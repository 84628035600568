import clsx from 'clsx'
import pluralize from 'pluralize'
import { useMemo, useState } from 'react'

import {
  ContainsDisplay,
  FvButton,
  HazardDetails,
  Icon,
} from '@fv/client-components'
import { formatNumber, getItemLabels } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { displayNmfc } from '../../features/commodities/commodityUtils'
import { useTypeNames } from '../../hooks/settings'
import { type LegItem } from './types'
import { UnitIndexDisplay } from './UnitIndexDisplay'

type LegLoadProps = {
  className: string
  item: LegItem
  load: FullShipment
}

export const LegLoad = ({ className, item, load }: LegLoadProps) => {
  const {
    description,
    dimensionsUOM,
    freightClass,
    hazardous,
    height,
    length,
    quantity,
    stopType,
    type,
    weight,
    weightUOM,
    width,
  } = item

  const hasLabel = useMemo(
    () => getItemLabels(load.documents, item._id).length > 0,
    [load.documents, item._id],
  )

  const [isExpanded, setExpanded] = useState(false)
  const { packageName } = useTypeNames()
  const hasDimensions = !!(height && length && width)
  const hasWeight = !!weight
  const nmfc = displayNmfc(item)
  const sendHazInfo = item.contains?.length === 1 && hazardous

  let stopAction = ''

  if (load.locations.length > 2) {
    stopAction = stopType === 'delivery' ? 'Drop off' : 'Pick up'
  }

  return (
    <li
      className={clsx(
        'standard-list__item',
        'standard-list__item--dashed',
        'items-start',
        'flex-nowrap',
        className,
      )}
    >
      <div className="ml-10 mt-[.15rem]">
        {!!hasLabel && (
          <>
            <FvButton
              icon="arrows-from-line"
              title="Labels and tracking"
              onClick={() => setExpanded(prevState => !prevState)}
            />{' '}
            /
          </>
        )}
        {stopAction}
        {description && ` ${description}`}
        {' / '}
        {pluralize(type ? packageName(type) : 'item', quantity, true)}
        {hasDimensions &&
          ` / Dimensions ${length} x ${width} x ${height} ${dimensionsUOM}.`}
        {nmfc && load.workflow === 'ltl' && ` / NMFC ${nmfc}`}
        {freightClass && ` / Freight class ${freightClass}`}
        {hasWeight && ` / Total weight ${formatNumber(weight)} ${weightUOM}.`}
        {hazardous && !item.contains?.some(i => i.hazardous?.length) && (
          <>
            {' / '}
            <Icon className="fa-fw color-secondary" icon="radiation-alt" />
            <span className="ml-1">
              Hazardous
              <HazardDetails hazardDetails={[hazardous]} />
            </span>
          </>
        )}
      </div>
      {isExpanded &&
        [...Array(item.quantity)].map((e, i) => (
          <div className="mt-2" key={`${item._id}-${i}`}>
            <UnitIndexDisplay item={item} index={i} load={load} />
          </div>
        ))}

      <div>
        {item.contains?.map(c => (
          <ContainsDisplay
            item={c}
            iconClassName={className}
            key={c._id}
            textMargin="ml-4"
            bottomMargin="mb-0"
            border={true}
            hazardous={sendHazInfo ? hazardous : undefined}
            containsIcon={
              <div className="relative left-[0.1rem] h-6 w-6 items-center rounded-full border-2 border-[#EDEACB] bg-fv-beer">
                <Icon
                  className={clsx(
                    'color-ghosted',
                    className,
                    'absolute left-[0.2rem] top-[.05rem] text-[#DBD59E]',
                  )}
                  icon={'arrow-turn-down-right'}
                  transform="down-2.5 shrink-5"
                />
              </div>
            }
          />
        ))}{' '}
      </div>
    </li>
  )
}
