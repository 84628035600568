import { Fragment } from 'react'

import { Icon, SliderPanel } from '@fv/client-components'
import { addressFuncs } from '@fv/client-core'
import { type PropsWithLoad } from '@fv/client-types'

import {
  ItemSummary,
  MissingItemSummary,
} from '../../features/commodities/ItemSummary'
import { getItemsForSequence } from '../../utils/shipmentFuncs'

type Props = PropsWithLoad<{
  activeIndex: number
  isOpen: boolean
  numberOfTrucks: number
  setOpen: (open: boolean) => void
}>

export const ItemSummaryPanel = ({
  activeIndex,
  isOpen,
  load,
  numberOfTrucks,
  setOpen,
}: Props) => {
  const { locations } = load

  return (
    <SliderPanel
      closeButtonPosition="right"
      closePanel={() => setOpen(false)}
      isOpen={isOpen}
    >
      {locations.map((x, ix) => {
        const isMultiStop = locations.length > 2
        if (!isMultiStop && ix === 0) return null
        const items = getItemsForSequence(x.sequence, load.items)

        return (
          <Fragment key={ix}>
            <ul className="standard-list--lcb-0 mb-4">
              {(isMultiStop || x.type === 'destination') && (
                <li className="standard-list__item">
                  <p className="mb-0 flex flex-nowrap items-start">
                    <Icon
                      icon="map-marker"
                      className="color-dark fa-fw flex-none"
                      transform="down-2.5"
                    />
                    <span>{addressFuncs.companyAddress(x)}</span>
                  </p>
                </li>
              )}

              {numberOfTrucks > 1 && (
                <li className="standard-list__item">
                  <Icon icon="truck" className="color-dark fa-fw" />
                  <span>Truck {activeIndex + 1}</span>
                </li>
              )}

              {!items.length ? (
                <MissingItemSummary />
              ) : (
                items.map(i => (
                  <ItemSummary
                    iconClassName="fa-fw flex-none"
                    item={i}
                    key={i._id}
                    label={
                      i.pickupLocationSequence === x.sequence
                        ? 'Picking up'
                        : 'Delivering'
                    }
                    contains={i.contains}
                  />
                ))
              )}
            </ul>

            {ix !== locations.length - 1 && <hr className="hr-divider" />}
          </Fragment>
        )
      })}
    </SliderPanel>
  )
}
