import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon, SliderPanel } from '@fv/client-components'
import { type EquipmentType } from '@fv/models'

import CheckboxField from '../../../components/inputs/CheckboxField'
import { supportMessage } from '../../../constants'
import { useEquipmentTypeEnums } from './hooks'
import { useUpdateFavoriteEquipment } from './mutations'

type Props = {
  initialFavorites: EquipmentType[]
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const FavoriteEquipmentPanel = ({
  initialFavorites,
  isOpen,
  setOpen,
}: Props) => {
  const [localFavorites, setLocalFavorites] = useState<EquipmentType[]>([])
  const equipmentTypeEnums = useEquipmentTypeEnums('truckload')
  const updateFavorites = useUpdateFavoriteEquipment()

  useEffect(() => {
    if (!isOpen) setLocalFavorites([])
    else setLocalFavorites(initialFavorites)
  }, [initialFavorites, isOpen])

  function toggleEquipmentType(e: ChangeEvent<HTMLInputElement>) {
    setLocalFavorites(prev => {
      const key = e.target.name as EquipmentType
      const nextFavorites = prev.filter(t => t !== key)

      if (e.target.checked) nextFavorites.push(key)
      return nextFavorites.sort((a, b) => a.localeCompare(b))
    })
  }

  function saveFavoriteEquipment(): void {
    if (updateFavorites.isLoading) return
    if (localFavorites.length < 3) {
      toast.error('Must select at least 3 equipment types.')
      return
    }

    updateFavorites
      .mutateAsync(localFavorites)
      .then(() => setOpen(false))
      .catch(e => {
        let message = 'Unable to update favorite equipment'
        if (e?.message) message += `: ${e.message}`
        else message += `, ${supportMessage}`
        toast.error(message)
      })
  }

  return (
    <SliderPanel
      className="flex flex-col flex-nowrap items-stretch"
      closePanel={() => setOpen(false)}
      isOpen={isOpen}
    >
      <h6 className="mb-0">Favorite equipment types</h6>
      <hr className="mb-6" />
      <div className="flex items-start !overflow-hidden">
        <div className="col-6 h-full overflow-auto pb-4">
          <h6 className="mb-0">Check your favorites</h6>
          <hr />
          {equipmentTypeEnums.map(t => (
            <CheckboxField
              checked={localFavorites.includes(t.key)}
              className="checkbox mb-4"
              disabled={updateFavorites.isLoading}
              key={t.key}
              label={t.name}
              labelClassName="limit-characters"
              name={t.key}
              onChange={toggleEquipmentType}
            />
          ))}
        </div>
        <div className="col-6 h-full overflow-auto pb-4 pl-4">
          <h6 className="mb-0">Your selections (3 min)</h6>
          <hr />
          <ul>
            {localFavorites.map(key => {
              const equipment = equipmentTypeEnums.find(e => e.key === key)

              return (
                <li className="standard-list__item limit-characters" key={key}>
                  <Icon icon="star-sharp" />
                  <span>{equipment?.name ?? key}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="flyout-footer-actions flex">
        <FvButton
          icon={updateFavorites.isLoading ? 'sync' : 'check'}
          onClick={saveFavoriteEquipment}
          type="button"
          theme="primary"
        >
          <span>Save</span>
        </FvButton>
        <FvButton
          theme="plain"
          icon="times"
          onClick={() => setOpen(false)}
          type="button"
        >
          <span>Cancel</span>
        </FvButton>
      </div>
    </SliderPanel>
  )
}

export default FavoriteEquipmentPanel
