import { type Dispatch, type SetStateAction, useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment, type Workflow } from '@fv/client-types'

import { usePermissions } from '../../../features/auth'
import DocumentList from '../../../features/documents/DocumentList'
import { MiniQuoteList } from '../../../features/mini-quote-list/MiniQuoteList'
import { useCarrierPublicSettings } from '../../../hooks/useCarrierPublicSettings'
import { legacyShipmentLink } from '../../../utils/stringTransforms'
import InfoBox from '../../InfoBox'
import { type PanelName } from '../LoadDetailPanel'
import ShipmentActions from '../ShipmentActions'
import { ActivePanelFooter } from './ActivePanelFooter'
import ActivePanelHeader from './ActivePanelHeader'

type ActiveLoadDetailProps = {
  load: FullShipment
  setActivePanel: Dispatch<SetStateAction<PanelName>>
}

export const ActiveLoadDetail = ({
  load,
  setActivePanel,
}: ActiveLoadDetailProps) => {
  const [isShowingRates, setShowingRates] = useState(false)
  const { canBook } = usePermissions()

  const {
    bol,
    cancel,
    documents,
    isLegacy,
    loadId,
    pickup,
    selectedQuote,
    status,
    workflow,
  } = load

  const isCanceled = status === 'canceled'
  const isUpdated = !!bol?.changedAfterDispatch
  const isApiDispatch = pickup?.method === 'api'
  const isApiCancellation = cancel?.mode === 'api' && cancel?.status === 'ok'
  const { carrierPublicSettings } = useCarrierPublicSettings(
    selectedQuote?.carrierId,
  )

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <div className="shipment-list-ancillary-details overflow-auto">
        {isCanceled &&
          (!isApiDispatch || isApiCancellation ? (
            <InfoBox>
              This shipment has been canceled and will not be picked up.
            </InfoBox>
          ) : (
            <InfoBox>
              Contact the carrier{' '}
              {carrierPublicSettings?.cancelPhone && (
                <FvLinkButton href={`tel:${carrierPublicSettings.cancelPhone}`}>
                  at {carrierPublicSettings.cancelPhone}
                </FvLinkButton>
              )}{' '}
              to cancel this shipment. It is marked as canceled in Freightview,
              but{' '}
              {selectedQuote?.providerName ??
                selectedQuote?.assetCarrierName ??
                'the carrier'}{' '}
              is still planning to pick it up.
            </InfoBox>
          ))}

        {isUpdated && !isCanceled && isApiDispatch && (
          <InfoBox>
            Your bill of lading has been updated. You need to call{' '}
            {carrierPublicSettings?.dispatchPhone && (
              <FvLinkButton href={`tel:${carrierPublicSettings.dispatchPhone}`}>
                {carrierPublicSettings.dispatchPhone}
              </FvLinkButton>
            )}{' '}
            and inform the carrier of your changes.
          </InfoBox>
        )}

        <ActivePanelHeader
          isShowingRates={isShowingRates}
          load={load}
          setActivePanel={setActivePanel}
          setShowingRates={setShowingRates}
        />

        {isShowingRates && (
          <div className="shipment-list-pricing">
            <MiniQuoteList
              isActiveCarriers
              key={selectedQuote?._id}
              load={load}
            />
          </div>
        )}

        {!isShowingRates && (
          <ShipmentActions
            setActivePanel={setActivePanel}
            shipment={load}
            showRatesPanel={() => setShowingRates(true)}
          />
        )}

        {!isShowingRates && (
          <div className="shipment-list-documents-ctn">
            <div className="divided-content divided-content--start">
              <p className="mb-0">
                <Icon icon="file" className="color-dark fa-fw" />
                <span>Shipment docs</span>
              </p>
              {!isCanceled &&
                (isLegacy ? (
                  <a
                    href={composeLegacyShareLink(loadId, workflow)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Icon icon="share" className="fa-fw" />
                    <span>Share</span>
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setActivePanel('share')
                    }}
                  >
                    <Icon icon="share" className="fa-fw" />
                    <span>Share</span>
                  </a>
                ))}

              {canBook && (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setActivePanel('upload')
                  }}
                >
                  <Icon icon="upload" />
                  <span>Add / Delete</span>
                </a>
              )}
            </div>
            <hr className="mx-0 my-2 mb-4" />
            <DocumentList documents={documents} isLegacy={isLegacy} />
          </div>
        )}
      </div>

      <ActivePanelFooter
        isShowingRates={isShowingRates}
        load={load}
        setShowingRates={setShowingRates}
      />
    </div>
  )
}

function composeLegacyShareLink(loadId: string, workflow?: Workflow) {
  const baseUrl = legacyShipmentLink(loadId, workflow)
  return `${baseUrl}/share`
}
