import clsx from 'clsx'
import uniqBy from 'lodash/uniqBy'
import upperFirst from 'lodash/upperFirst'

import { ContainsDisplay, Icon } from '@fv/client-components'
import { getDistanceDisplay, getEquipmentLabel } from '@fv/client-core'
import {
  type ListShipment,
  type RefNum,
  type SlimTrackDetail,
  type Workflow,
} from '@fv/client-types'

import { useLocale, useTypeNames } from '../../hooks/settings'
import {
  getDestination,
  getItemDescription,
  getOrigin,
} from '../../utils/shipmentFuncs'
import { type SharedShipment } from '../share/types'

type Props = {
  isExpanded: boolean
  shipment: ListShipment | SharedShipment
  showMixedPallets?: boolean
}

export const ShipmentDetailRow = ({
  isExpanded,
  shipment,
  showMixedPallets,
}: Props) => {
  const { equipment, selectedQuote, tracking, workflow } = shipment
  const { accessorialName, equipmentName } = useTypeNames()
  const locale = useLocale()
  const description = getItemDescription(shipment)
  const hasDescription = Boolean(description)
  const hasName = workflow === 'truckload'
  const isExpandable = !!equipment.accessorials?.length

  const refNumsDisplay = composeRefNums({
    refNums: allRefNums(shipment),
    workflow,
    tracking,
  })
  const equipmentLabel = hasName
    ? getEquipmentLabel({
        altEquipmentNames: equipment.alternateTypes?.map(equipmentName),
        equipmentName: equipmentName(
          selectedQuote?.equipmentType ?? equipment.type,
        ),
        isAwarded: !!selectedQuote,
      })
    : null

  return (
    <>
      <li
        className={clsx('standard-list__item flex flex-nowrap items-start', {
          'standard-list__item--dashed': isExpandable && isExpanded,
        })}
      >
        <div>
          <div className={clsx(showMixedPallets ? 'pb-2' : '')}>
            <span className="flex">
              <Icon
                icon={equipment.isHazardous ? 'radiation-alt' : 'clipboard'}
                transform="down-1.75"
                className={clsx('fa-fw color-dark mr-1', {
                  'color-secondary': equipment.isHazardous,
                })}
              />
              <p className="mb-0 pr-16">
                {refNumsDisplay}
                {hasName && refNumsDisplay && ' / '}
                {hasName && equipmentLabel}
                {(hasName || refNumsDisplay) && ' / '}
                {!!shipment.distance &&
                  `${getDistanceDisplay(shipment, locale)} / `}
                {hasDescription && upperFirst(description)}
                {!hasName &&
                  !refNumsDisplay &&
                  !hasDescription &&
                  'No description given'}
              </p>
            </span>
          </div>
          {showMixedPallets &&
            shipment.items?.map(i =>
              i.contains?.map(c => (
                <ContainsDisplay
                  item={c}
                  key={c._id}
                  border={false}
                  textMargin="ml-2"
                  bottomMargin="mb-2"
                  containsIcon={
                    <div className="relative h-6 w-6 items-center rounded-full border-2 border-[#EDEACB] bg-fv-beer">
                      <Icon
                        className={clsx(
                          'color-ghosted absolute left-[0.2rem] top-[.05rem] text-[#DBD59E]',
                        )}
                        icon={'arrow-turn-down-right'}
                        transform="down-2.5 shrink-3"
                      />
                    </div>
                  }
                />
              )),
            )}
        </div>
      </li>

      {isExpandable && isExpanded && (
        <li className="standard-list__item flex flex-nowrap items-start">
          <Icon
            icon="dot-circle"
            className="fa-fw color-ghosted flex-none"
            transform="down-1.5"
          />
          <p className="mb-0">
            {equipment.accessorials
              ?.map(a => accessorialName(a.key))
              .join(' / ')}
          </p>
        </li>
      )}
    </>
  )
}

function composeRefNums({
  refNums,
  tracking,
  workflow,
}: {
  refNums: RefNum[]
  tracking?: SlimTrackDetail
  workflow: Workflow
}) {
  let refNumsDisplay = ''

  if (workflow === 'ltl' && tracking?.trackingNumber) {
    refNumsDisplay = `PRO# ${tracking.trackingNumber}`
  }

  if (workflow === 'parcel' && tracking?.trackingNumber) {
    refNumsDisplay = `Tracking# ${tracking.trackingNumber}`
  }

  if (refNums?.length > 0) {
    if (refNumsDisplay) refNumsDisplay += ' / '
    refNumsDisplay += `Ref# ${refNums.map(n => n.value).join(' / ')}`
  }

  return refNumsDisplay
}

function allRefNums({
  refNums,
  locations,
  workflow,
}: Pick<ListShipment, 'refNums' | 'locations' | 'workflow'>) {
  const origin = getOrigin({ locations })
  const destination = getDestination({ locations })
  refNums = [...(refNums ?? [])]

  if (workflow === 'ltl' || workflow === 'parcel') {
    const locs = [origin, destination]

    locs.forEach(loc => {
      if (!loc) return

      loc.refNums?.forEach(locRefNum => {
        if (!refNums.some(r => locRefNum.value === r.value)) {
          refNums.push(locRefNum)
        }
      })
    })
  }

  return uniqBy(refNums, r => r.value.trim())
}
