import clsx from 'clsx'
import dayjs from 'dayjs'
import { type MouseEvent, useState } from 'react'

import { FvButton, FvLinkButton, Icon } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import { StopList } from '../../components/shared/StopList'
import { useBolLinkState } from '../../hooks/shipments/useBolLinkState'
import { getApplicableAccessorials } from '../../utils/locationFuncs'
import { usePrefetchTracking } from '../track/queries'
import { TrackingHistory } from '../track/TrackingHistory'
import ShipmentCardHeader from './ShipmentCardHeader'
import { ShipmentDetailRow } from './ShipmentDetailRow'

type Props = {
  isActive: boolean
  onClick: () => void
  shipment: ListShipment
}

const getDateDifference = (targetDate?: string) => {
  if (!targetDate) return { expired: true }
  const now = dayjs()
  const expiryDate = dayjs(targetDate)

  return {
    duration: expiryDate.fromNow(),
    expired: expiryDate.isBefore(now),
  }
}

export const ShipmentCard = ({ isActive, onClick, shipment }: Props) => {
  const {
    equipment,
    loadId,
    locations,
    selectedQuote,
    status,
    tracking,
    workflow,
    bidExpiration,
  } = shipment

  const dateDiff = getDateDifference(
    bidExpiration?.utc ? dayjs.utc(bidExpiration?.utc).toString() : undefined,
  )
  const [isExpanded, setExpanded] = useState(false)
  const prefetchTracking = usePrefetchTracking()
  const {
    fvBolUrl,
    hideFvBolFromSpotQuote,
    isProcessing: isBolProcessing,
  } = useBolLinkState(shipment)
  const inProcess = status === 'pending' || status === 'awarded'
  const hasTracking = !inProcess && !!tracking?.currentLocation?.summary
  const isExpandable = Boolean(
    hasTracking ||
      !!equipment.accessorials?.length ||
      locations.find(
        x =>
          !!getApplicableAccessorials(x, workflow)?.length ||
          !!x.refNums?.length,
      ),
  )

  return (
    <div
      className={clsx('shipment-card', status, {
        'shipment-card--active': isActive,
      })}
      data-loadid={shipment.loadId} // for scroll to on list type toggle
      onClick={e => {
        onClick()
        scrollIntoPosition(e)
      }}
    >
      <ShipmentCardHeader shipment={shipment} active={isActive} />

      <div className="shipment-card__content">
        {inProcess && dateDiff.duration && (
          <div
            className={clsx(
              'bg-fv-beer border-fv-gray absolute right-0 top-0 border-b border-l px-2 py-1 text-xs',
              isActive && 'right-[calc(2rem-1px)] border-r',
            )}
          >
            <Icon icon="clock" className="-mr-[.2rem]" />
            <span>
              {' '}
              <>
                {dateDiff?.expired ? 'Bidding expired' : 'Bidding expires'}{' '}
                {dateDiff?.duration}{' '}
              </>
            </span>
          </div>
        )}
        {isExpandable && (
          <FvButton
            className="shipment-card__view-more-btn"
            theme="plain"
            onClick={() => setExpanded(s => !s)}
            onMouseEnter={() => {
              if (!hasTracking) return
              prefetchTracking(loadId)
            }}
          >
            <Icon icon="arrows-from-line" />
          </FvButton>
        )}

        {status !== 'pending' && !hideFvBolFromSpotQuote && fvBolUrl && (
          <FvLinkButton
            rel="noreferrer"
            target="_blank"
            href={fvBolUrl}
            className="shipment-card__bol-link"
            icon={isBolProcessing ? 'sync' : 'file'}
          >
            FV BOL
          </FvLinkButton>
        )}

        <ul className="standard-list">
          <ShipmentDetailRow isExpanded={isExpanded} shipment={shipment} />

          <StopList
            isExpanded={isExpanded}
            locations={locations}
            tracking={tracking}
            workflow={workflow}
            quote={selectedQuote}
          />

          {hasTracking && (
            <li className="standard-list__item flex items-start">
              <TrackingHistory
                tracking={shipment.tracking}
                loadId={loadId}
                theme={isExpanded ? 'abbreviated' : 'summary'}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

function scrollIntoPosition(
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
) {
  const shipmentCard = e.currentTarget
  const shipmentList = document.getElementById('shipmentList')

  if (
    !shipmentCard ||
    !shipmentList ||
    shipmentCard.className.includes('--active')
  ) {
    return
  }

  // Get position of card relative to top of list container viewPort
  const shipmentCardRelativePos = shipmentCard.getBoundingClientRect()

  // Get position of 1st card (used to set scroll distance)
  const firstShipmentCard =
    document.querySelector<HTMLDivElement>('.shipment-card')
  const firstCardStart = firstShipmentCard?.offsetTop ?? 0

  // Get height of list with overflow sans padding
  const styles = window.getComputedStyle(shipmentList)
  const shipmentListContentHeight =
    shipmentList.scrollHeight - parseFloat(styles.paddingBottom)

  // Get height of visible list container (without overflow)
  const shipmentListContainerHeight = shipmentList.clientHeight

  // Get start position of card relative to entire list
  const shipCardOffset = e.currentTarget.offsetTop

  // To prevent a card from being clicked and not scrolling to the top because
  // there is not enough content below it, add required padding to move card
  // to the same position as others that have enough scrollable real estate.
  // else, set list container back to default padding
  if (
    shipCardOffset >
    shipmentListContentHeight - shipmentListContainerHeight
  ) {
    const bottomPadding =
      shipmentListContainerHeight - shipmentCardRelativePos.top + 'px'

    shipmentList.style.paddingBottom = bottomPadding
  } else {
    shipmentList.style.paddingBottom = ''
  }

  shipmentList.scroll({
    top: shipCardOffset - firstCardStart,
    behavior: 'smooth',
  })
}
