import { useMutation, useQueryClient } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useAppContext } from '../settings'
import { messageKeys } from './useMessages'

export const newMessageId = 'newMessage'

type MessageDeliveryStatus =
  | 'bounced'
  | 'deferred'
  | 'delivered'
  | 'dropped'
  | 'failed'
  | 'na'
  | 'opened'
  | 'processed'
  | 'queued'
  | 'sent'
  | 'viewed'

type MessageSubType =
  | 'award'
  | 'cancel'
  | 'change'
  | 'confirm'
  | 'decline'
  | 'dispute'
  | 'message'
  | 'not-awarded'
  | 'public'
  | 'quote'
  | 'reject'
  | 'request'
  | 'retract-tender'
  | 'retract'
  | 'share'
  | 'shipper'
  | 'tender'
  | 'tracking-update'
  | 'upload'

export type Message = {
  _id: string
  accountId: string
  createdDate: string
  isLegacy: boolean
  loadId: string
  messageText: string
  participants: Array<{
    carrierId?: string
    deliveryStatus?: MessageDeliveryStatus
    email?: string
    id?: string
    isAuthor?: boolean
    name?: string
    readDate?: string
    role: 'carrier' | 'other' | 'shipper' | 'system'
  }>
  quoteRequestId: string
  subType: MessageSubType
  type: 'audit' | 'share' | 'spot-quote'
}

async function postMessage(dto: {
  carrierId?: string
  loadId: string
  messageText: string
  userId?: string
}): Promise<Message> {
  const { carrierId, loadId, messageText, userId } = dto
  const endpoint = `${apiUri}/shipments/${loadId}/messages`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      carrierId,
      messageText,
      userId,
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // message
  throw response.errorMessage
}

export function usePostMessage() {
  const contextQuery = useAppContext()
  const queryClient = useQueryClient()
  const user = contextQuery.data?.user

  return useMutation(postMessage, {
    onMutate: dto => {
      const message: Message = {
        _id: newMessageId,
        createdDate: new Date().toISOString(),
        messageText: dto.messageText,
        participants: [
          {
            email: user?.email,
            id: user?._id,
            isAuthor: true,
            role: 'shipper',
          },
          {
            carrierId: dto.carrierId,
            id: dto.userId,
            role: 'carrier',
          },
        ],
        accountId: user?.accountIds[0] ?? '',
        isLegacy: false,
        loadId: dto.loadId,
        quoteRequestId: dto.loadId,
        subType: 'message',
        type: 'spot-quote',
      }

      queryClient.setQueryData(
        messageKeys.load(dto.loadId),
        (prev?: Message[]) => {
          if (!prev) return [message]
          return prev.concat(message)
        },
      )
    },
  })
}
