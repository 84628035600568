import clsx from 'clsx'

import { FvButton } from '@fv/client-components'
import { type MakeOptional } from '@fv/client-types'

import { ContainsForm, type ContainsFormProps } from './ContainsForm'
import { useItemsState } from './loadItemsContext'
import { PanelItemSummary } from './PanelItemSummary'

type ContainsProps = MakeOptional<ContainsFormProps, 'onChange'> & {
  index: number
  editing?: boolean
  onRemoveClick?: () => void
  onEditClick: () => void
}
export const ContainsRow = (props: ContainsProps) => {
  const { index, value, editing, onRemoveClick, onEditClick, onChange } = props
  const { workflow } = useItemsState()
  return (
    <div
      className={clsx('border-fv-blue-150 flex gap-3 border-b p-4', {
        'bg-fv-blue-100': editing,
      })}
    >
      <div>
        <div className="beer-container flex h-8 w-8 items-center justify-center rounded-full">
          {index}
        </div>
      </div>
      <div className="relative flex-1">
        <div className="divided-content divided-content--start divided-content--end absolute right-0 top-0">
          {!editing && <FvButton icon="pen-alt" onClick={onEditClick} />}
          {onRemoveClick && <FvButton icon="trash" onClick={onRemoveClick} />}
        </div>
        <div className="px-0">
          {!editing && <PanelItemSummary item={value} workflow={workflow} />}
          {editing && onChange && (
            <ContainsForm {...props} onChange={onChange} />
          )}
        </div>
      </div>
    </div>
  )
}
