import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import { ActionList } from './ActionList'

export const List = ({
  children,
  className,
}: PropsWithClassName<PropsWithChildren>) => {
  return (
    <div className={clsx('divide-fv-gray-300 divide-y', className)}>
      {children}
    </div>
  )
}

type ListItemProps = PropsWithClassName<
  PropsWithChildren<{
    actions?: ReactNode
    custom?: boolean
  }>
>
export const ListItem = ({
  children,
  actions,
  className,
  custom,
}: ListItemProps) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-x-2',
        className,
        !custom && 'py-3',
      )}
    >
      {children}
      {actions}
    </div>
  )
}

type LiaProps = PropsWithChildren<PropsWithClassName>
export const ListItemActions = ({ children, className }: LiaProps) => {
  return (
    <div className={clsx(className, 'ml-auto')}>
      <ActionList>{children}</ActionList>
    </div>
  )
}

export const ListItemConnector = ({ className }: PropsWithClassName) => {
  return (
    <div
      className={clsx(
        className,
        'border-fv-gray h-px flex-1 border-t border-dotted',
      )}
    />
  )
}

export const ListHeaderActions = ({
  children,
  className,
}: PropsWithClassName<PropsWithChildren>) => {
  return (
    <div
      className={clsx(
        'mt-2 border-b border-dashed border-[#ccc] pb-2',
        className,
      )}
    >
      {children}
    </div>
  )
}
