import { type SelectFieldOption } from '@fv/client-types'

import { SelectField } from '../../../components/inputs/SelectField'
import { useAccountFeatures } from '../../auth'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'

export const DefaultWorkflowForm = () => {
  const features = useAccountFeatures()
  const accountSettings = useAccountSettings()
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  const options: SelectFieldOption[] = [
    {
      text: 'LTL',
      value: 'ltl',
    },
  ]

  if (features.truckLoad) {
    options.push({
      text: 'Truckload',
      value: 'truckload',
    })
  }

  if (features.parcel) {
    options.push({
      text: 'Parcel',
      value: 'parcel',
    })
  }

  if (options.length === 1) {
    return null
  }

  return (
    <li className="standard-list__item">
      <div className="flex items-center">
        <label htmlFor="defaultMode" className="leading-[1.6rem]">
          Default shipping mode
        </label>
        <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
        <div>
          <SelectField
            options={options}
            name="defaultMode"
            onChange={e => {
              updateAccountSetting(
                'quoting.defaultWorkflow',
                e.currentTarget.value,
              )
            }}
            value={accountSettings.quoting?.defaultWorkflow || 'ltl'}
          />
        </div>
      </div>
    </li>
  )
}
