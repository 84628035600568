import dayjs from 'dayjs'

export const dateRangeBuilders: any = {
  'last-30-days': () => {
    const start = dayjs.utc().subtract(1, 'month').startOf('day').valueOf()
    const end = dayjs.utc().endOf('day').valueOf()
    return `${start}-${end}`
  },
  'last-7-days': () => {
    const start = dayjs.utc().subtract(1, 'week').startOf('day').valueOf()
    const end = dayjs.utc().endOf('day').valueOf()
    return `${start}-${end}`
  },
  'next-week': () => {
    const start = dayjs
      .utc()
      .add(1, 'week')
      .startOf('week')
      .startOf('day')
      .valueOf()

    const end = dayjs.utc().add(1, 'week').endOf('week').endOf('day').valueOf()
    return `${start}-${end}`
  },
  'this-month': () => {
    const start = dayjs.utc().startOf('month').startOf('day').valueOf()
    const end = dayjs.utc().endOf('month').endOf('day').valueOf()
    return `${start}-${end}`
  },
  'this-week': () => {
    const start = dayjs.utc().startOf('week').startOf('day').valueOf()
    const end = dayjs.utc().endOf('week').endOf('day').valueOf()
    return `${start}-${end}`
  },
  today: () => {
    const start = dayjs.utc().startOf('day').valueOf()
    const end = dayjs.utc().endOf('day').valueOf()
    return `${start}-${end}`
  },
  tomorrow: () => {
    const start = dayjs.utc().add(1, 'day').startOf('day').valueOf()
    const end = dayjs.utc().add(1, 'day').endOf('day').valueOf()
    return `${start}-${end}`
  },
  yesterday: () => {
    const start = dayjs.utc().subtract(1, 'day').startOf('day').valueOf()
    const end = dayjs.utc().subtract(1, 'day').endOf('day').valueOf()
    return `${start}-${end}`
  },
}
