import clsx from 'clsx'
import { type ChangeEvent } from 'react'

import { CheckboxField } from '@fv/client-components'
import { validateEmailListInput } from '@fv/client-core'

import { Help } from '../../components/Help'
import { InputGroup } from '../../components/inputs/InputGroup'
import { type FormLocation } from '../../types/FormLocation'

type Props = {
  location: FormLocation
  onChange: (value: Pick<FormLocation, 'copyBOL' | 'shares'>) => void
}
export const ShareShipmentWith = ({ location, onChange }: Props) => {
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateEmailListInput(e.target)
    onChange({ shares: e.target.value, copyBOL: location.copyBOL })
  }
  return (
    <>
      <div className={clsx('form-group my-2 w-full', {})}>
        <div className="flex items-center">
          <CheckboxField
            checked={location.copyBOL}
            className="checkbox checkbox--vertical"
            label="Share this shipment with"
            name={`${location.sequence}-copyBOL`}
            onChange={e => {
              onChange({ copyBOL: e.target.checked, shares: location.shares })
            }}
          />
          <Help>
            Enter one or more email addresses. We'll send them a link to the
            shipment and subscribe them for tracking updates on the shipment.
          </Help>
        </div>
      </div>

      {location.copyBOL && (
        <InputGroup
          className={clsx('mb-0 mt-4 basis-full', {})}
          inputType="text"
          inputProps={{
            onChange: handleEmailChange,
            name: `${location.sequence}-shares`,
            value: location.shares,
            placeholder: 'bill@example.com, ted@example.com',
          }}
        />
      )}
    </>
  )
}
