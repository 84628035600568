import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'

import { FvButton, Icon, ValidatedForm } from '@fv/client-components'

import { supportMessage } from '../../constants'
import { useNotes } from '../../hooks/messages'
import {
  type AddLoadNote,
  useNoteMutation,
} from '../../hooks/messages/useNotes'
import InfoBox from '../InfoBox'
import { InputAdornment, InputGroup } from '../inputs/InputGroup'

type Props = {
  loadId: string
}

export const NoteList = ({ loadId }: Props) => {
  const notesQuery = useNotes(loadId)
  const { addNote, isAdding } = useNoteMutation(loadId)
  const [note, setNote] = useState<AddLoadNote>({
    message: '',
    source: 'shipper',
    type: 'general',
  })
  const handleSubmit = async (f: HTMLFormElement) => {
    await addNote(note)
    setNote({ ...note, message: '' })
    f.reset()
  }

  if (notesQuery.isLoading && notesQuery.isFetching) {
    return (
      <InfoBox className="mt-4" icon="spinner">
        Loading...
      </InfoBox>
    )
  }

  return (
    <div>
      <ValidatedForm onValidSubmit={handleSubmit}>
        <InputGroup
          className=""
          inputType="text"
          inputProps={{
            name: '',
            onChange: e => {
              setNote({ ...note, message: e.target.value })
            },
            value: note.message,
          }}
          endContent={
            <InputAdornment position="end">
              <FvButton
                type="submit"
                theme="default"
                disabled={isAdding}
                icon={isAdding ? 'spinner' : 'plus'}
                transform="up-1"
              >
                Add note
              </FvButton>
            </InputAdornment>
          }
        />
      </ValidatedForm>
      {notesQuery.isError && (
        <InfoBox className="mt-4" icon="triangle">
          Unable to load notes, {supportMessage}
        </InfoBox>
      )}

      {!notesQuery.data?.length ? (
        <InfoBox className="mt-4">No notes have been added.</InfoBox>
      ) : (
        <ul className="standard-list--lcb-0">
          {notesQuery.data?.map(n => (
            <li
              key={n._id}
              className={clsx('standard-list__item', { 'fv-flash': n.isNew })}
            >
              <p className="mb-0">
                <span className="text-xs">
                  {(n.createdBy?.name || 'Freightview') + ' on '}
                  {dayjs(n.createdDate).format('MMM D / h:mm a')}
                </span>
                <br />
                <span className="note-message flex-start flex">
                  <Icon
                    icon="sticky-note"
                    transform="down-2.5"
                    className="color-dark"
                  />
                  <span>{n.message}</span>
                </span>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
