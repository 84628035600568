import clsx from 'clsx'
import { useRef, useState } from 'react'
import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  Icon,
  SliderPanel,
  ValidatedForm,
} from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'

import { ActionListItem } from '../../../components/ActionList'
import { CountIndicator } from '../../../components/CountIndicator'
import { FormActions } from '../../../components/forms/FormActions'
import { InputGroup } from '../../../components/inputs/InputGroup'
import {
  List,
  ListHeaderActions,
  ListItem,
  ListItemActions,
} from '../../../components/List'
import { Loading } from '../../../components/Loading'
import { SliderPanelLayout } from '../../../components/SliderPanelLayout'
import {
  ContactListInput,
  type ContactListInputHandle,
} from '../../spot-quote-contacts/ContactListInput'
import {
  useRemoveSpotQuoteGroup,
  useUpsertSpotQuoteGroup,
} from '../../spot-quote-contacts/mutations'
import {
  useSpotQuoteContacts,
  useSpotQuoteGroups,
} from '../../spot-quote-contacts/queries'
import {
  ALL_CONTACTS_ID,
  type SpotQuoteGroup,
} from '../../spot-quote-contacts/types'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { SettingsSection } from '../SettingsSection'

export const SpotContactsPage = () => {
  const [isAdding, toggleAdding, setAdding] = useToggle()
  const [editing, setEditing] = useState<SpotQuoteGroup>()
  const { data: groups, isLoading } = useSpotQuoteGroups()

  const getSliderTitle = () => {
    if (editing?.groupId === ALL_CONTACTS_ID) {
      return 'Managing all contacts'
    }
    return isAdding ? 'Create a spot-quote group' : 'Edit this spot-quote group'
  }

  const closePanel = () => {
    setAdding(false)
    setEditing(undefined)
  }
  if (isLoading) {
    return (
      <div className="m-8">
        <Loading />
      </div>
    )
  }
  return (
    <SettingsPageLayout>
      <SettingsSection title="Spot quote groups">
        <ListHeaderActions>
          <FvButton icon="plus" onClick={toggleAdding}>
            Add spot-quote group
          </FvButton>
        </ListHeaderActions>
        <List className="divide-y ">
          {(groups ?? []).map(g => (
            <ListItem
              key={g.groupId}
              actions={<Actions onEdit={() => setEditing(g)} group={g} />}
            >
              <Icon icon="envelopes" className="fa-fw color-dark" />
              <CountIndicator count={g.users.length} />
              {g.groupName}
            </ListItem>
          ))}
        </List>
      </SettingsSection>

      <SliderPanel isOpen={isAdding || !!editing} closePanel={closePanel}>
        <SliderPanelLayout
          titleIcon="envelopes"
          transform="down-1"
          title={getSliderTitle()}
        >
          <Form group={editing} onSave={closePanel} onCancel={closePanel} />
        </SliderPanelLayout>
      </SliderPanel>
    </SettingsPageLayout>
  )
}

type FormProps = {
  group?: SpotQuoteGroup
  onSave: () => void
  onCancel: () => void
}
export const Form = ({ group, onSave, onCancel }: FormProps) => {
  const contactInputRef = useRef<ContactListInputHandle>(null)
  const upsertGroup = useUpsertSpotQuoteGroup(true)
  const isAllContacts = group?.groupId === ALL_CONTACTS_ID
  const { register, value } = useFormModelState({
    initialValue: {
      groupName: group?.groupName ?? '',
    },
  })
  const getSubmitText = () => {
    if (isAllContacts) {
      return 'Save contacts'
    }
    return group ? 'Update group' : 'Create group'
  }
  const allContacts = useSpotQuoteContacts()
  const [users, setUsers] = useState(group?.users?.map(u => u.email) ?? [])
  const options = isAllContacts
    ? undefined
    : allContacts.data?.map(d => d.email)

  const handleSubmit = async () => {
    // unfortunately we have to depend on result.value to get the list of users
    // even though the state is managed in this component.
    // The problem is `validateAndAddValue` fires the change event, which updates the state
    // however, the react state lifecycle prevents us from accessing the new state value
    const result = contactInputRef.current?.validateAndAddValue(true)
    if (!result?.success) return
    await upsertGroup.mutateAsync({
      groupId: group?.groupId,
      groupName: value.groupName,
      emails: result.value ?? users,
    })
    onSave()
  }

  return (
    <ValidatedForm className="flex h-full flex-col">
      {!isAllContacts && (
        <InputGroup
          inputType="text"
          label="Group name"
          className="mt-2"
          required
          inputProps={{ ...register('groupName') }}
          autoFocus={!group}
        />
      )}
      <label className="label">Contacts:</label>
      <div
        className="grow basis-0 overflow-hidden"
        style={{ paddingBottom: isAllContacts ? '20px' : '225px' }}
      >
        <ContactListInput
          ref={contactInputRef}
          value={users}
          onChange={setUsers}
          options={options}
          autoFocus={isAllContacts || !!group}
        />
      </div>

      <FormActions
        loading={upsertGroup.isLoading}
        className="mb-0"
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitText={getSubmitText()}
      />
    </ValidatedForm>
  )
}

type ActionProps = {
  group: SpotQuoteGroup
  onEdit: () => void
}
const Actions = ({ group, onEdit }: ActionProps) => {
  const removeGroup = useRemoveSpotQuoteGroup()
  const handleRemoveClick = async () => {
    if (window.confirm('Are you sure you want to remove this group?')) {
      await removeGroup.mutateAsync({ groupId: group.groupId })
    }
  }
  return (
    <ListItemActions>
      <ActionListItem icon="pen-alt" onClick={onEdit} />

      <ActionListItem
        icon="trash"
        className={clsx(
          group.groupId === ALL_CONTACTS_ID && 'pointer-events-none invisible',
        )}
        iconClass="text-fv-orange"
        onClick={handleRemoveClick}
      />
    </ListItemActions>
  )
}
