import clsx from 'clsx'
import dayjs from 'dayjs'

import { FvButton, FvLinkButton, Tooltip } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import {
  type LabeledDocument,
  useLabeledDocuments,
} from '../../hooks/documents'
import { composeFvBolUrl } from '../../utils/shipmentFuncs'
import DeleteDocumentButton from './DeleteDocumentButton'

type DocumentListProps = Pick<FullShipment, 'documents' | 'isLegacy'> & {
  className?: string
  loadId?: string
  canDelete?: boolean
  onClick?: (doc: LabeledDocument) => void
}

const DocumentList = ({
  documents,
  isLegacy,
  className,
  loadId,
  onClick,
  canDelete = false,
}: DocumentListProps) => {
  const fvBolUrl = composeFvBolUrl({ documents, isLegacy })
  const docs = useLabeledDocuments(documents)

  return (
    <div className={clsx('divide-y divide-fv-gray', className)}>
      {docs.map((doc, index) => (
        <div key={doc._id ?? index} className="flex">
          <div className="limit-characters flex-1">
            <Tooltip
              label={
                doc.uploadDate
                  ? `Uploaded ${dayjs
                      .utc(doc.uploadDate)
                      .local()
                      .format('YYYY-MM-DD')}`
                  : 'Upload date unavailable'
              }
            >
              <>
                {onClick && (
                  <FvButton
                    icon="file"
                    className="p-[.6rem] pl-0 w-full text-left block"
                    iconClass="fa-fw"
                    onClick={() => onClick(doc)}
                  >
                    {doc.label}
                  </FvButton>
                )}
                {!onClick && (
                  <FvLinkButton
                    icon="file"
                    className="p-[.6rem] pl-0 w-full text-left block"
                    iconClass="fa-fw"
                    href={doc.label === 'Freightview BOL' ? fvBolUrl : doc.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {doc.label}
                  </FvLinkButton>
                )}
              </>
            </Tooltip>
          </div>
          {doc.source === 'shipper' && canDelete && loadId && (
            <DeleteDocumentButton documentId={doc._id} loadId={loadId} />
          )}
        </div>
      ))}

      {!documents?.length && (
        <InfoBox>No documents have been uploaded.</InfoBox>
      )}
    </div>
  )
}

export default DocumentList
