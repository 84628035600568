import clsx from 'clsx'
import dayjs from 'dayjs'
import { memo } from 'react'
import toast from 'react-hot-toast'

import { CheckboxField, FvDate, Icon, Tooltip } from '@fv/client-components'
import { states } from '@fv/models'

import { ActionList, ActionListItem } from '../../../components/ActionList'
import { Price } from '../../../components/shared/Price'
import { useTypeNames } from '../../../hooks/settings'
import { ContractedRateForm } from './ContractedRateForm'
import { useRemoveContractedRates } from './mutations'
import { type ContractedRate, type RateLocation } from './types'

type RowProps = {
  rate: ContractedRate
  checked?: boolean
  isEditing: boolean
  onSubmit?: () => void
  onCancel?: () => void
  flash?: boolean
  onChecked: (rateId: string, checked: boolean) => void
}
export const ContractedRateRow = ({
  rate,
  checked,
  onChecked,
  isEditing,
  flash,
  onSubmit,
  onCancel,
}: RowProps) => {
  const { equipmentName } = useTypeNames()
  const removeRate = useRemoveContractedRates(rate.carrierId)
  const isExpired = dayjs.utc().isAfter(rate.endDate)
  return (
    <div
      className={clsx('standard-list__item', 'flex flex-wrap items-center', {
        'fv-flash': flash && rate.errors?.length,
      })}
      key={rate._id}
    >
      <CheckboxField
        checked={checked}
        name={rate._id}
        onChange={e => {
          const { checked } = e.target
          onChecked(rate._id, checked)
        }}
      />
      <div className="contracted-rates-lane flex-1">
        <div className="mb-0 flex flex-col leading-[1.6rem]">
          <div className="flex items-center">
            <Tooltip label={rate.errors?.[0]?.message}>
              <Icon
                className={clsx('fa-fw', {
                  'color-dark': !rate.errors?.length,
                  'color-warning': !!rate.errors?.length,
                })}
                icon={!rate.errors?.length ? 'route' : 'exclamation-triangle'}
              />
            </Tooltip>
            <span>
              <RateLocDisplay loc={rate.origin} />
              &nbsp;to&nbsp;
              <RateLocDisplay loc={rate.destination} />
              &nbsp;using {rate.equipmentTypes.map(equipmentName).join(' / ')}
            </span>
          </div>
          <div className="flex items-center">
            <Tooltip label={isExpired ? 'Expired' : ''}>
              <Icon
                className={clsx('fa-fw', {
                  'color-dark': !isExpired,
                  'color-warning': isExpired,
                })}
                icon={isExpired ? 'exclamation-triangle' : 'calendar-alt'}
              />
            </Tooltip>
            <span>
              Valid from <FvDate val={rate.startDate} format="MMM DD YYYY" /> to{' '}
              <FvDate val={rate.endDate} format="MMM DD YYYY" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <Price amount={rate.rate.amount} className="font-mono" />
      </div>

      <ActionList className="ml-6">
        <ActionListItem
          icon="pen-alt"
          to={buildEditLink(rate._id, isEditing)}
        />
        <ActionListItem
          icon="trash"
          iconClass="text-fv-orange"
          loading={removeRate.isLoading}
          onClick={() => {
            if (removeRate.isLoading) return

            const isConfirmed = window.confirm(
              'Are you sure you want to remove this rate?',
            )

            if (!isConfirmed) return

            removeRate
              .mutateAsync([rate._id])
              .catch(e => {
                toast.error(e.message)
              })
              .finally(() => {
                removeRate.reset()
              })
          }}
        />
      </ActionList>

      {isEditing && (
        <div className="col-12 edit-contracted-rate-ctn mb-4 mt-4 overflow-hidden">
          <ContractedRateForm
            rate={rate}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </div>
      )}
    </div>
  )
}

function buildEditLink(rateId: string, isEditing: boolean) {
  return isEditing
    ? '/settings/contracted-rates'
    : `/settings/contracted-rates/edit/${rateId}`
}

const RateLocDisplay = memo(function RateLocDisplay({
  loc,
}: {
  loc: RateLocation
}) {
  if (loc.state) {
    const stateName =
      states.find(s => s.abbreviation === loc.state)?.name ?? loc.state
    return <span>{stateName}</span>
  }
  if (loc.postalCodeEnd) {
    return (
      <span>
        {loc.postalCode}-{loc.postalCodeEnd}
      </span>
    )
  }

  const prefix =
    loc.meta?.city && loc.meta?.state
      ? `${loc.meta.city}, ${loc.meta.state} `
      : null

  return (
    <span>
      {prefix}
      {loc.postalCode}
    </span>
  )
})
