import clsx from 'clsx'
import dayjs from 'dayjs'

import { Icon } from '@fv/client-components'
import { type Audit } from '@fv/models'

type AuditNotesProps = {
  className?: string
  notes?: Audit['notes']
}

const AuditNotes = ({ className = '', notes }: AuditNotesProps) => {
  if (!notes) return null

  const sortedNotes = notes.sort(
    (a, b) =>
      new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf(),
  )

  return (
    <ul className={clsx('standard-list--lcb-0', className)}>
      {sortedNotes.map(n => {
        const timestamp = dayjs(n.createdDate).format('ddd MMM D h:mma')

        return (
          <li className="standard-list__item flex" key={n._id}>
            <Icon
              icon={n.source === 'shipper' ? 'sticky-note' : 'paper-plane'}
              className={clsx('fa-fw', {
                'color-dark': n.source === 'shipper',
                'color-warning': n.source === 'system',
              })}
              transform="down-2.5"
            />

            <p className="mb-0">
              {timestamp} - {n.message}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default AuditNotes
