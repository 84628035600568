import { type FullShipment } from '@fv/client-types'

import { routes } from '../../constants'
import { usePermissions } from '../../features/auth'
import {
  type BookingMode,
  type ReferrerType,
} from '../../features/booking/types'

type MyShipment = Pick<
  FullShipment,
  | 'isLegacy'
  | 'loadId'
  | 'quoteRequestId'
  | 'selectedQuote'
  | 'workflow'
  | 'status'
>

export const composeEditShipmentUrl = (
  shipment: MyShipment,
  referrer: ReferrerType = 'listing',
  mode?: BookingMode,
): string | undefined => {
  const { selectedQuote, loadId, quoteRequestId, isLegacy } = shipment
  if (!selectedQuote || isLegacy) return

  if (shipment.workflow === 'truckload') {
    return routes.bookTruckload({
      mode,
      quoteRequestId,
      referrer,
      selectedQuotes: { [loadId]: selectedQuote._id },
    })
  }

  return routes.book({
    loadId,
    quoteId: selectedQuote._id,
  })
}

export function useEditBookingPage(shipment: MyShipment) {
  const { isLegacy, selectedQuote } = shipment
  const { canBook } = usePermissions()
  const canEdit =
    !!selectedQuote?._id &&
    canBook &&
    !isLegacy &&
    shipment.status !== 'canceled' &&
    shipment.workflow !== 'parcel'
  const composeEditLink = (referrer?: ReferrerType, mode?: BookingMode) =>
    composeEditShipmentUrl(shipment, referrer, mode)

  return {
    canEdit,
    composeEditLink,
  }
}
