import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

import { downloadCSV } from '@fv/client-core'

import { shipperFetch } from '../../utils/shipperFetch'
import { migrateParams } from '../../utils/stringTransforms'
import { useAnalyticsParams } from './hooks'
import {
  type AnalyticsView,
  type LaneAnalyticsDTO,
  lanes,
  type ProviderAnalyticsDTO,
  providers,
  spotQuote,
  type SpotQuoteAnalyticsDTO,
  users,
  type UserStatsDTO,
} from './types'

// Ensure order in queryKey used by cache
function normalizeQueryString(qs: string) {
  const source = new URLSearchParams(qs)
  const target = new URLSearchParams()

  migrateParams('pickupDate', source, target)
  migrateParams('bookedDate', source, target)
  migrateParams('direction[]', source, target)
  migrateParams('mode[]', source, target)
  migrateParams('carrier[]', source, target)
  migrateParams('quotedBy[]', source, target)
  migrateParams('bookedBy[]', source, target)
  migrateParams('isInterline', source, target)
  migrateParams('pricingMethod[]', source, target)
  migrateParams('pricingType[]', source, target)
  migrateParams('tag[]', source, target)
  migrateParams('location[]', source, target)

  return target.toString()
}

const analyticsKeys = {
  all: ['analytics'] as const,
  type: (view: AnalyticsView, qs: string) =>
    [...analyticsKeys.all, view, normalizeQueryString(qs)] as const,
}

async function fetchLaneAnalytics(qs: string): Promise<LaneAnalyticsDTO> {
  return shipperFetch(`/analytics/lanes?${qs}`)
}

async function fetchProviderAnalytics(
  qs: string,
): Promise<ProviderAnalyticsDTO> {
  return shipperFetch(`/analytics/providers?${qs}`)
}

async function fetchSpotQuoteAnalytics(
  qs: string,
): Promise<SpotQuoteAnalyticsDTO[]> {
  return shipperFetch(`/analytics/spot-quote?${qs}`)
}

async function fetchUserReportAnalytics(qs: string): Promise<UserStatsDTO[]> {
  return shipperFetch(`/analytics/users?${qs}`)
}

export function useLaneAnalytics() {
  const { pickupDate, qs, view } = useAnalyticsParams()

  const lanesQuery = useQuery(
    analyticsKeys.type(lanes, qs),
    () => fetchLaneAnalytics(qs),
    {
      enabled: Boolean(pickupDate) && view === lanes,
    },
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      lanesQuery.error instanceof Error &&
      lanesQuery.error.name === 'EventValidationError'
    ) {
      toast.error(lanesQuery.error.message)
    }
  }, [lanesQuery.error])

  return lanesQuery
}

export function useProviderAnalytics() {
  const { pickupDate, qs, view } = useAnalyticsParams()

  const providersQuery = useQuery(
    analyticsKeys.type(providers, qs),
    () => fetchProviderAnalytics(qs),
    {
      enabled: Boolean(pickupDate) && view === providers,
    },
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      providersQuery.error instanceof Error &&
      providersQuery.error.name === 'EventValidationError'
    ) {
      toast.error(providersQuery.error.message)
    }
  }, [providersQuery.error])

  return providersQuery
}

export function useSpotQuoteAnalytics() {
  const { pickupDate, qs, view } = useAnalyticsParams()

  const query = useQuery(
    analyticsKeys.type(spotQuote, qs),
    () => fetchSpotQuoteAnalytics(qs),
    {
      enabled: Boolean(pickupDate) && view === spotQuote,
    },
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      query.error instanceof Error &&
      query.error.name === 'EventValidationError'
    ) {
      toast.error(query.error.message)
    }
  }, [query.error])

  return query
}

export function useUserAnalytics() {
  const { pickupDate, qs, view } = useAnalyticsParams()

  const query = useQuery(
    analyticsKeys.type(users, qs),
    () => fetchUserReportAnalytics(qs),
    {
      enabled: Boolean(pickupDate) && view === users,
    },
  )

  return query
}

function exportUserReport(qs: string) {
  return shipperFetch<{ csv: string }>(`/analytics/users-export?${qs}`, {
    method: 'POST',
  })
}

export function useExportUserReport() {
  return useMutation(exportUserReport, {
    onSuccess: ({ csv }) => {
      downloadCSV(csv, 'freightview_user_report')
      toast.success('User report exported successfully.')
    },
    retry: false,
  })
}
