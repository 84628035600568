import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useRef, useState } from 'react'

import {
  CheckboxField,
  Icon,
  SliderPanel,
  ValidatedForm,
} from '@fv/client-components'
import {
  displayWorkflow,
  type MakeOptional,
  type Workflow,
  workflows,
} from '@fv/client-types'
import {
  type AuditThresholdSettings,
  type InvoiceAutomationSettings,
  type InvoicingSettings,
} from '@fv/models'

import { FormActions } from '../../../components/forms/FormActions'
import {
  InputAdornment,
  InputGroup,
} from '../../../components/inputs/InputGroup'
import { RadioItem } from '../../../components/inputs/RadioField'
import { useAccountFeatures } from '../../auth'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'

type SliderProps = {
  isOpen: boolean
  close: () => void
}

type AuditThresholdFormModel = MakeOptional<
  AuditThresholdSettings,
  'threshold' | 'type'
>
type InvoiceSettingsFormModel = Omit<InvoicingSettings, 'automation'> & {
  automation?: {
    [K in keyof InvoiceAutomationSettings]: AuditThresholdFormModel
  }
}

export const AuditThresholdSlider = (props: SliderProps) => {
  const features = useAccountFeatures()
  const accountSettings = useAccountSettings()
  const initialSettings = () => {
    return cloneDeep(
      accountSettings?.invoicing ?? {
        autoArchiveShipment: true,
        emailDispute: false,
      },
    )
  }

  const [localSettings, setLocalSettings] =
    useState<InvoiceSettingsFormModel>(initialSettings())
  const { updateAccountSetting, isBusy } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  const onSubmit = () => {
    updateAccountSetting('invoicing', localSettings)
    props.close()
  }

  const onCancel = () => {
    setLocalSettings(initialSettings())
    props.close()
  }
  const enabledWorkflows = workflows.filter(w => {
    return (
      w === 'ltl' ||
      (w === 'parcel' && features.parcel) ||
      (w === 'truckload' && features.truckLoad)
    )
  })

  return (
    <SliderPanel isOpen={props.isOpen} closePanel={onCancel}>
      <ValidatedForm onValidSubmit={() => onSubmit()}>
        <ul className="standard-list standard-list--fcp-0 standard-list--lcb-0">
          {enabledWorkflows.map(w => (
            <WorkflowForm
              workflow={w}
              value={
                localSettings.automation?.[w] ?? {
                  enabled: false,
                }
              }
              setValue={val =>
                setLocalSettings(prev => ({
                  ...prev,
                  automation: {
                    ...(prev.automation ?? {}),
                    [w]: val,
                  },
                }))
              }
              key={w}
            />
          ))}
        </ul>
        <hr className="mt-0" />

        <FormActions
          className="mt-8"
          onCancel={onCancel}
          loading={isBusy}
          submitText="Save"
        />
      </ValidatedForm>
    </SliderPanel>
  )
}

type WorkflowFormProps = {
  workflow: Workflow
  value: AuditThresholdFormModel
  setValue: (value: AuditThresholdFormModel) => void
}

const WorkflowForm = ({ workflow, value, setValue }: WorkflowFormProps) => {
  const fieldName = (name: string) => `automation.${workflow}.${name}`
  const flatRef = useRef<HTMLInputElement>(null)
  const percentRef = useRef<HTMLInputElement>(null)
  const threshType = value.type

  useEffect(() => {
    if (threshType === 'flat' && percentRef.current) {
      percentRef.current.value = ''
    } else if (threshType === 'percent' && flatRef.current) {
      flatRef.current.value = ''
    }
  }, [threshType, value.threshold])

  return (
    <li className="standard-list__item py-5">
      <div>
        <CheckboxField
          name={fieldName('enabled')}
          label={displayWorkflow(workflow)}
          onChange={e => {
            setValue({
              enabled: e.target.checked,
              type: 'percent',
            })
          }}
          checked={value.enabled}
        />
        <div className="border-t-fv-gray my-4 h-px border-t border-dashed" />
      </div>

      {!value.enabled && (
        <span className="text-sm">Automated auditing disabled</span>
      )}

      {value.enabled && (
        <div className="mt-5 flex items-center gap-4">
          <div className="flex items-center gap-2">
            <RadioItem
              name={fieldName('type')}
              value="percent"
              onChange={() => {
                setValue({
                  ...value,
                  type: 'percent',
                  threshold: undefined,
                })
                setTimeout(() => percentRef.current?.focus(), 1)
              }}
              checked={value.type === 'percent'}
            />
            <InputGroup
              className="mb-0 w-28"
              inputType="text"
              required={threshType === 'percent'}
              inputProps={{
                name: fieldName('percent.threshold'),
                onChange: e => {
                  const val = e.target.value
                    ? parseFloat(e.target.value)
                    : undefined
                  setValue({
                    ...value,
                    threshold: val,
                  })
                },
                value:
                  threshType === 'percent'
                    ? (value.threshold?.toString() ?? '')
                    : '',
                placeholder: 'i.e. 15',
                type: 'number',
                disabled: threshType !== 'percent',
                ref: percentRef,
                min: 0,
              }}
              startContent={
                <InputAdornment position="start">
                  <Icon icon="percent" />
                </InputAdornment>
              }
            />
            <span className="b1150:hidden b1450:hidden b1350:block">
              of quote
            </span>
          </div>
          <div className="bg-fv-beer border-fv-beer-700  flex aspect-square h-8 w-8 items-center justify-center rounded-full border">
            or
          </div>

          <div className="flex items-center gap-2">
            <RadioItem
              name={fieldName('type')}
              value="flat"
              onChange={() => {
                setValue({
                  ...value,
                  type: 'flat',
                  threshold: undefined,
                })
                setTimeout(() => flatRef.current?.focus(), 1)
              }}
              checked={value.type === 'flat'}
            />
            <InputGroup
              className="mb-0 w-28"
              inputType="text"
              required={threshType === 'flat'}
              inputProps={{
                name: `automation.${workflow}.flat.threshold`,
                onChange: e => {
                  const val = e.target.value
                    ? parseFloat(e.target.value)
                    : undefined
                  setValue({
                    ...value,
                    threshold: val,
                  })
                },
                value:
                  threshType === 'flat'
                    ? (value.threshold?.toString() ?? '')
                    : '',
                type: 'number',
                placeholder: 'i.e. 5',
                disabled: threshType !== 'flat',
                ref: flatRef,
                min: 0,
              }}
              startContent={
                <InputAdornment position="start">
                  <Icon icon="dollar-sign" />
                </InputAdornment>
              }
            />
            <span className="b1150:hidden b1450:hidden b1350:block">
              of quote
            </span>
          </div>
        </div>
      )}
    </li>
  )
}
