import { useQuery } from '@tanstack/react-query'
import { type PropsWithChildren } from 'react'

import { inProcessStatusOptions } from '../../components/CustomFilterForm/filterOptions'
import { type FilterConfig } from '../../components/CustomFilterForm/types'
import {
  getFilterQueryString,
  type SavedFilterGroup,
} from '../../types/SavedFilter'
import { useAccountFeatures } from '../auth'
import { useAccountSettings } from '../settings/account-settings/hooks'
import {
  fetchInProcessShipments,
  inProcessShipmentKeys,
} from '../shipment-list/hooks/useInProcessShipments'
import {
  allFilter,
  bookingErrorsFilter,
  ltlGroupFilter,
  parcelGroupFilter,
  truckloadGroupFilter,
} from './in-process-filters'
import { ShipmentFilterListProvider } from './ShipmentFilterListProvider'
import {
  directionFilter,
  liveLoadFilter,
  pickupDateFilter,
  shippingModeFilter,
  spotFilter,
  useAccountFilters,
} from './useFilterConfigs'
import { useHiddenViews, useSavedViews } from './useSavedViews'

export const InProcessFiltersProvider = ({ children }: PropsWithChildren) => {
  const filters = useInProcessFilters()
  const filterConfig = useInProcessFilterConfig()
  return (
    <ShipmentFilterListProvider
      state={{
        listType: 'inProcessList',
        filters,
        filterConfig,
      }}
    >
      {children}
    </ShipmentFilterListProvider>
  )
}

const useInProcessFilters = () => {
  const hiddenViews = useHiddenViews()
  const savedViews = useSavedViews('inProcessList')
  const bookingErrors = useBookingErrors()
  const orderedFilters = useOrderedWorkflowFilters()
  const inProcessSystemFilters = [
    ...(bookingErrors.data?.data.length ? [bookingErrorsFilter] : []),
    allFilter,
    ...orderedFilters,
  ]
  const systemFilters =
    inProcessSystemFilters.filter(f => !hiddenViews.find(id => id === f._id)) ??
    []

  const custom: SavedFilterGroup = {
    _id: 'custom-group',
    filter: {},
    isLocked: true,
    isDisabled: true,
    icon: 'arrow-down',
    name: 'Custom filters',
    children: savedViews,
    queryString: '',
  }

  return savedViews.length ? [...systemFilters, custom] : systemFilters
}

const useOrderedWorkflowFilters = () => {
  const accountSettings = useAccountSettings()
  const { parcel, truckLoad } = useAccountFeatures()
  const filters = [ltlGroupFilter]

  if (truckLoad) {
    if (accountSettings.quoting?.defaultWorkflow === 'truckload') {
      filters.unshift(truckloadGroupFilter)
    } else {
      filters.push(truckloadGroupFilter)
    }
  }
  if (parcel) {
    if (accountSettings.quoting?.defaultWorkflow === 'parcel') {
      filters.unshift(parcelGroupFilter)
    } else {
      filters.push(parcelGroupFilter)
    }
  }

  return filters
}
const useBookingErrors = () => {
  const queryString = getFilterQueryString(bookingErrorsFilter)
  return useQuery(inProcessShipmentKeys.bookingErrors(), () =>
    fetchInProcessShipments(queryString),
  )
}
const useInProcessFilterConfig = (): FilterConfig[] => {
  const { locationFilter } = useAccountFilters()

  return [
    pickupDateFilter,
    {
      label: 'Status',
      name: 'status[]',
      options: inProcessStatusOptions,
    },
    directionFilter,
    shippingModeFilter,
    locationFilter,
    liveLoadFilter,
    spotFilter,
  ]
}
