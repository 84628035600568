import upperFirst from 'lodash/upperFirst'

import { FvLinkButton, Icon } from '@fv/client-components'
import {
  formatNumber,
  getDistanceDisplay,
  getEquipmentLabel,
} from '@fv/client-core'
import { type PropsWithLoad } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { routes } from '../../constants'
import { HasPermission } from '../../features/auth'
import { useCurrency, useLocale, useTypeNames } from '../../hooks/settings'

type SummaryProps = PropsWithLoad<{
  equipmentType?: EquipmentType
  isBooking?: boolean
  showItems?: () => void
}>

export const TruckloadSummary = ({
  equipmentType,
  isBooking,
  load,
  showItems,
}: SummaryProps) => {
  const { accessorialName, equipmentName } = useTypeNames()
  const locale = useLocale()
  const priceParts = useCurrency()

  const { distance, equipment, items, workflow } = load
  if (workflow !== 'truckload') return null

  const {
    accessorials = [],
    alternateTypes,
    declaredValue,
    description,
    isHazardous,
    type,
    weight,
    weightUOM,
  } = equipment

  const [dollars, cents] = priceParts(declaredValue)
  const equipmentLabel = getEquipmentLabel({
    equipmentName: equipmentName(equipmentType ?? type),
    ...(!equipmentType && {
      altEquipmentNames: alternateTypes?.map(equipmentName),
    }),
  })

  return (
    <>
      <li className="b1200:first:pl-0">
        <p className="mb-0 flex flex-nowrap items-start">
          <Icon icon="truck" className="fa-fw color-dark" transform="down-3" />
          <span>{equipmentLabel}</span>
        </p>
      </li>
      {!!distance && (
        <li>
          <Icon icon="route" className="fa-fw color-dark" />
          <span>{getDistanceDisplay(load, locale)}</span>
        </li>
      )}
      <li>
        {!isBooking && !!items?.length ? (
          <FvLinkButton icon="list" iconClass="fa-fw" onClick={showItems}>
            <span>{upperFirst(description)}</span>
          </FvLinkButton>
        ) : (
          <>
            <Icon icon="quote-left" className="fa-fw color-dark" />
            <span>{upperFirst(description)}</span>
          </>
        )}
      </li>

      <li>
        <Icon icon="weight-hanging" className="fa-fw color-dark" />
        <span>
          {formatNumber(weight, locale)} {weightUOM}.
        </span>
      </li>

      {declaredValue && (
        <li>
          <Icon icon="dollar-sign" className="fa-fw color-dark" />
          <span>
            {dollars}.{cents}
          </span>
        </li>
      )}

      {isHazardous && (
        <li>
          <Icon icon="radiation-alt" className="fa-fw color-secondary" />
          <span>Hazardous</span>
        </li>
      )}

      {accessorials.length > 0 && (
        <li className="flex flex-nowrap items-start">
          <Icon icon="list" className="fa-fw color-dark flex-none" />
          <span>
            Accessorials:{' '}
            {accessorials.map(a => accessorialName(a.key)).join(', ')}
          </span>
        </li>
      )}
    </>
  )
}

type ActionProps = PropsWithLoad<{
  cancelRequest: (all: boolean, redirect: string) => void
  hasAwardedEquipment?: boolean
  isBooking?: boolean
  isCanceling: boolean
  isEditingLoad?: boolean
  openCancellationPanel: () => void
}>

export const TruckloadActions = ({
  cancelRequest,
  hasAwardedEquipment,
  isBooking,
  isCanceling,
  isEditingLoad,
  load,
  openCancellationPanel,
}: ActionProps) => {
  const { isLegacy, quoteRequestId, status, workflow } = load
  if (workflow !== 'truckload') return null

  const canEditRequest = status === 'pending' && !isLegacy
  const canStartOver = !hasAwardedEquipment && !isBooking

  function startOver() {
    const confirmed = window.confirm(
      'Starting over will cancel this quote request. Are you sure?',
    )

    if (!confirmed) return
    cancelRequest(
      true,
      routes.quote('truckload', { id: quoteRequestId, mode: 'copy' }),
    )
  }

  return (
    <>
      {canStartOver && (
        <HasPermission name="book">
          <li className="standard-list__item">
            <FvLinkButton
              disabled={isCanceling}
              icon={isCanceling ? 'spinner' : 'edit'}
              onClick={startOver}
            >
              Start over
            </FvLinkButton>
          </li>
        </HasPermission>
      )}

      {canEditRequest && (
        <HasPermission name="book">
          <li className="standard-list__item">
            <FvLinkButton
              disabled={isCanceling}
              icon="pen-alt"
              to={routes.quote('truckload', {
                id: quoteRequestId,
                mode: 'edit',
              })}
            >
              Edit request
            </FvLinkButton>
          </li>
        </HasPermission>
      )}

      {!isBooking && (
        <HasPermission name="book">
          <li className="standard-list__item">
            <FvLinkButton
              disabled={isCanceling}
              icon={isCanceling ? 'spinner' : 'trash'}
              onClick={openCancellationPanel}
            >
              Cancel this quote request
            </FvLinkButton>
          </li>
        </HasPermission>
      )}

      {isBooking && !isEditingLoad && (
        <li className="standard-list__item">
          <FvLinkButton
            disabled={isCanceling}
            icon="arrow-to-left"
            to={routes.rates(load)}
          >
            Back to quote request
          </FvLinkButton>
        </li>
      )}
    </>
  )
}
