import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'

import { FvButton, Icon, type IconName, Tooltip } from '@fv/client-components'
import {
  type DetailedAccountProvisioningDto,
  type ProvisionerLocationDto,
} from '@fv/client-types'

import { ActionList, ActionListItem } from '../../components/ActionList'
import { ListItem } from '../../components/List'
import { Loading } from '../../components/Loading'
import { useAccountLocations } from '../addresses/useAddress'
import { HasPermission } from '../auth'
import { provisionLocationComponentProvider } from './CarrierComponentProvider'
import { needsShipperAttention } from './utils'

type ListItemProps = {
  carrier: DetailedAccountProvisioningDto
  onEditClick: (locId?: string) => void
  onDeleteClick: () => void
}
export const AccountCarrierListItem = ({
  carrier,
  onEditClick,
  onDeleteClick,
}: ListItemProps) => {
  const [expanded, setExpanded] = useState(false)
  const needsAttention = needsShipperAttention(carrier)
  const isActive = !needsAttention && carrier.carrierStatus === 'active'
  const hasEditLoc = provisionLocationComponentProvider[carrier.code]?.(
    carrier.isBeta,
  )

  let icon: IconName = isActive ? 'link' : 'clock'
  let tooltip = isActive
    ? `${carrier.carrierName} is successfully connected.`
    : `A connection with ${carrier.carrierName} is not available.`

  if (needsAttention) {
    icon = 'exclamation-triangle'
    tooltip = `${carrier.carrierName} requires additional details.`
  }

  return (
    <>
      <ListItem
        className={clsx(
          needsAttention &&
            'bg-red-50 -mx-4 px-4 !border-red-200 !border-b !border-b-red-200 -mb-px z-10 relative',
        )}
      >
        <div>
          <Tooltip label={tooltip}>
            <Icon
              icon={icon}
              className={clsx(
                {
                  'text-green-400': isActive,
                  'text-fv-gray-500': !needsAttention && !isActive,
                  'text-red-400 animate-pulse': needsAttention,
                },
                'fa-fw',
              )}
            />
          </Tooltip>
        </div>
        <div className="flex-1">{carrier.carrierName}</div>
        <HasPermission name="manage">
          <ActionList>
            <ActionListItem
              onClick={() => setExpanded(!expanded)}
              icon="angle-down"
              className={clsx('transition-transform', {
                'rotate-180': expanded,
              })}
              title="Expand"
              theme="plain"
            />
            <ActionListItem
              className="test"
              theme="plain"
              icon="pen-alt"
              title="Edit"
              onClick={() => onEditClick()}
            />
            <ActionListItem
              theme="plain"
              iconClass="text-fv-orange"
              icon="trash"
              title="Remove"
              onClick={onDeleteClick}
            />
          </ActionList>
        </HasPermission>
      </ListItem>
      {expanded && (
        <ProvisionedCarrierSummary
          carrier={carrier}
          onLocationEdit={hasEditLoc ? locId => onEditClick(locId) : undefined}
        />
      )}
    </>
  )
}

type SummaryProps = {
  carrier: DetailedAccountProvisioningDto
  onLocationEdit?: (locationId: string) => void
}
const ProvisionedCarrierSummary = ({
  carrier,
  onLocationEdit,
}: SummaryProps) => {
  const { isLoading: locationsLoading } = useAccountLocations()
  const locations = orderBy(
    carrier?.locations ?? [],
    d => !!d.thirdParty,
    'asc',
  )
  const loading = locationsLoading
  return (
    <div className="bg-fv-beer-light !border-fv-beer-dark !border-b relative mb-3  mt-1 border p-4 z-10">
      <div className="border-l-fv-beer-dark border-t-fv-beer-dark bg-fv-beer-light absolute -top-2 left-8 h-4 w-4 rotate-45  border-l border-t" />
      {(!carrier || loading) && <Loading />}
      {carrier && !loading && (
        <div className="divide-fv-beer-dark divide-y divide-dotted">
          <p className="mb-2">
            Carrier rep name: {carrier.salesRepFirstName ?? ''}{' '}
            {carrier.salesRepLastName ?? ''}
            <br />
            Carrier rep phone: {carrier.salesRepPhone || '-'}
            <br />
            Carrier rep email(s):{' '}
            {carrier.salesReps?.map(e => e.email)?.join(',') ?? '-'}
          </p>

          {locations?.map(l => (
            <LocationListItem
              key={l.locationId}
              location={l}
              carrier={carrier}
              onEdit={
                onLocationEdit ? () => onLocationEdit(l.locationId) : undefined
              }
            />
          ))}
        </div>
      )}
    </div>
  )
}

type LocationProps = {
  location: ProvisionerLocationDto
  carrier: DetailedAccountProvisioningDto
  onEdit?: () => void
}
const LocationListItem = ({ location, carrier, onEdit }: LocationProps) => {
  const { data: locations } = useAccountLocations()
  const accountLocation = locations?.find(l => l._id === location.locationId)
  const addrDisplay = location.thirdParty
    ? 'Third Party'
    : accountLocation?.formatted
  const showBetaFxfe = carrier.code === 'fxfe' && carrier.isBeta

  const accountNumber = location.details?.find(
    d =>
      d.type === carrier.accountLocationIdentifierCred ||
      d.type === 'account-number',
  )?.value
  return (
    <div className="flex flex-wrap py-2" key={location.locationId}>
      <div className="flex-1 pr-8 leading-6">{addrDisplay}</div>
      <div className="text-sm">
        {showBetaFxfe && (
          <div>
            <LocCredEntry
              name="freight-account-number"
              location={location}
              label="Freight:"
            />
            <LocCredEntry
              name="direct-account-number"
              location={location}
              label="Freight Direct:"
            />
          </div>
        )}
        {!showBetaFxfe && (
          <>
            {location.status === 'active' && (
              <>
                <Icon icon="link" className="color-dark" />
                <span>{accountNumber ?? 'Active'}</span>
              </>
            )}
            {(location.status === 'new' || location.status === 'testing') &&
              'Pending'}
            {(location.status === 'error' || location.status === 'none') &&
              'No rates'}
          </>
        )}
      </div>
      {!!onEdit && (
        <div className="pl-8">
          <FvButton onClick={onEdit} icon="pen-alt" />
        </div>
      )}
    </div>
  )
}

type LocCredProps = {
  name: string
  location: ProvisionerLocationDto
  label: string
}
const LocCredEntry = ({ name, location, label }: LocCredProps) => {
  const cred = location.details?.find(d => d.type === name)
  if (!cred) return null

  return (
    <>
      <span>
        {label} {cred.value}
      </span>
      <br />
    </>
  )
}
