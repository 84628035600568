import { FedEx, FvButton, Icon } from '@fv/client-components'

import InfoBox from '../../../components/InfoBox'
import {
  useAccountCarrierActions,
  useAccountCarrierFromState,
  useIsBeta,
} from '../AccountCarrierFormProvider'
import { UsernamePasswordForm } from '../components/UsernamePasswordForm'
import { type CredentialFormProps } from '../types'
import { needsShipperAttention } from '../utils'

export const FedexCredForm = (props: CredentialFormProps) => {
  const accountCarrier = useAccountCarrierFromState()
  const { toggleCarrierOverride } = useAccountCarrierActions()
  const isBeta = useIsBeta()
  const isConnected = !needsShipperAttention({
    code: 'fxfe',
    details: accountCarrier?.details,
    isBeta,
  })
  return (
    <>
      <div className="mb-4 border-b">
        {!isConnected && (
          <InfoBox>
            To begin using <FedEx /> with Freightview, simply connect your{' '}
            <FedEx /> account to your Freightview account by clicking 'Connect
            my account'.
          </InfoBox>
        )}
        {isConnected && (
          <InfoBox>
            Remember to enter account details for each of your locations by
            expanding the FedEx carrier on this page and clicking the button
            next to the location.
          </InfoBox>
        )}
        <div className="mb-4 flex items-center gap-x-2">
          <div>
            <FedEx /> connection status:
          </div>
          {isConnected && (
            <div>
              <Icon icon="check" />
              <span>Connected</span>
            </div>
          )}
          {!isConnected && (
            <>
              <div>
                <Icon icon="exclamation-triangle" className="text-red-400" />
                <span>Not connected</span>
              </div>
              <div className="h-px border-fv-gray border-t flex-1 border-dotted" />

              <div>
                <FvButton theme="primary" onClick={toggleCarrierOverride}>
                  Connect my account
                </FvButton>
              </div>
            </>
          )}
        </div>
      </div>
      <UsernamePasswordForm {...props} />
    </>
  )
}
