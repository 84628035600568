import clsx from 'clsx'
import React, {
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
} from 'react'

import {
  AmountField,
  FvButton,
  FvLinkButton,
  Icon,
  type IconProps,
  TextField,
} from '@fv/client-components'

import { removeUndefinedProps } from '../../utils/removeUndefinedProperties'
import { Help } from '../Help'
import { RadioField } from './RadioField'
import { SelectField } from './SelectField'
import YesNoInput from './YesNoInput'

type Props = {
  className?: string
  helpText?: string
  label?: ReactNode
  locked?: boolean
  name: string
  required?: boolean
  labelClassName?: string
}

export const InputGroupWrapper = ({
  children,
  className,
  helpText,
  label,
  locked,
  name,
  labelClassName,
  required,
}: PropsWithChildren<Props>) => {
  return (
    <div className={clsx('form-group', className)}>
      {locked && (
        <Icon
          className="color-secondary mr-1"
          icon="lock"
          transform="shrink-1"
        />
      )}

      {label && (
        <label
          className={clsx('mb-2 inline-block', labelClassName, { required })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      {helpText && <Help transform="down-1">{helpText}</Help>}

      <div className="input-group flex">{children}</div>
    </div>
  )
}

interface InputGroupProps extends Omit<Props, 'name'> {
  endContent?: ReactNode
  startContent?: ReactNode
}

interface AmountGroup extends InputGroupProps {
  inputProps: ComponentPropsWithRef<typeof AmountField>
  inputType: 'amount'
}

interface RadioGroup extends InputGroupProps {
  inputProps: ComponentPropsWithoutRef<typeof RadioField>
  inputType: 'radio'
}

interface SelectGroup extends InputGroupProps {
  inputProps: ComponentPropsWithRef<typeof SelectField>
  inputType: 'select'
}

interface TextGroup extends InputGroupProps {
  inputProps: ComponentPropsWithRef<typeof TextField>
  inputType: 'text'
}
interface YesNoGroup extends InputGroupProps {
  inputProps: ComponentPropsWithRef<typeof YesNoInput>
  inputType: 'yes-no'
}

interface CustomGroup extends InputGroupProps {
  inputProps: ComponentPropsWithRef<typeof TextField>
  inputType: 'custom'
}

export const InputGroup = ({
  endContent,
  inputProps,
  inputType,
  locked,
  required,
  startContent,
  children,
  autoFocus,
  ...rest
}: PropsWithChildren<
  AmountGroup | RadioGroup | SelectGroup | TextGroup | CustomGroup | YesNoGroup
> & { autoFocus?: boolean }) => {
  let inputNode: ReactNode = children
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus && ref?.current) {
      ref.current.focus()
    }
  })

  const sharedProps = {
    disabled: locked || inputProps.disabled,
    required,
  }

  const wrapperProps = {
    ...rest,
    locked,
    name: inputProps.name,
    required,
  }
  const className = clsx('form-control', inputProps.className)

  if (inputType === 'amount') {
    inputNode = (
      <AmountField
        ref={ref}
        {...sharedProps}
        {...removeUndefinedProps(inputProps)}
        className={className}
      />
    )
  }

  if (inputType === 'radio') {
    inputNode = (
      <RadioField
        {...sharedProps}
        {...removeUndefinedProps(inputProps)}
        className={className}
      />
    )
  }

  if (inputType === 'select') {
    inputNode = (
      <SelectField
        {...sharedProps}
        {...removeUndefinedProps(inputProps)}
        className={className}
      />
    )
  }

  if (inputType === 'text') {
    inputNode = (
      <TextField
        ref={ref}
        {...sharedProps}
        {...removeUndefinedProps(inputProps)}
        className={className}
      />
    )
  }

  if (inputType === 'yes-no') {
    inputNode = (
      <YesNoInput
        {...sharedProps}
        {...removeUndefinedProps(inputProps)}
        className={className}
      />
    )
  }

  return (
    <InputGroupWrapper {...wrapperProps}>
      {startContent}
      {inputNode}
      {endContent}
    </InputGroupWrapper>
  )
}

type InputAdornmentProps = PropsWithChildren<{
  className?: string
  icon?: IconProps['icon']
  iconClass?: string
  label?: string
  htmlFor?: string
  position?: 'start' | 'end'
}>

export const InputAdornment = ({
  children,
  className,
  htmlFor,
  icon,
  iconClass = 'color-dark',
  label,
  position = 'end',
}: InputAdornmentProps) => {
  const containsButton = React.Children.toArray(children).some(
    child => React.isValidElement(child) && child.type === FvButton,
  )
  const containsLinkButton = React.Children.toArray(children).some(
    child => React.isValidElement(child) && child.type === FvLinkButton,
  )
  return (
    <label htmlFor={htmlFor} className="flex">
      <div
        className={clsx(
          className,
          containsLinkButton
            ? '[&>a]:flex [&>a]:h-[2.8rem] [&>a]:items-center [&>a]:border [&>a]:border-[#b3b3b3] [&>a]:bg-white [&>a]:px-[.6rem]'
            : !containsButton &&
                !containsLinkButton &&
                'flex items-center justify-center border border-[#b3b3b3] bg-white px-[.6rem]',
          position === 'start' && 'border-r-0',
          position === 'end' && 'border-l-0',
          containsButton &&
            `${
              position === 'start'
                ? '-mr-px'
                : position === 'end'
                  ? '-ml-px'
                  : ''
            }`,
          containsLinkButton &&
            `${
              position === 'start'
                ? '-mr-px'
                : position === 'end'
                  ? '-ml-px'
                  : ''
            }`,
        )}
      >
        {children}
        {icon && <Icon icon={icon} className={iconClass} />}
        {label && <span>{label}</span>}
      </div>
    </label>
  )
}
