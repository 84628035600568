import { useState } from 'react'

import { FvButton, Icon, type IconName } from '@fv/client-components'
import { displayWorkflow, type Workflow, workflows } from '@fv/client-types'

import { useAccountFeatures } from '../../auth'
import { AuditThresholdSlider } from './AuditThresholdSlider'
import { useAccountSettings } from './hooks'

export const AuditThresholdForm = () => {
  const features = useAccountFeatures()
  const [isOpen, setIsOpen] = useState(false)

  const enabledWorkflows = workflows.filter(w => {
    return (
      w === 'ltl' ||
      (w === 'parcel' && features.parcel) ||
      (w === 'truckload' && features.truckLoad)
    )
  })

  return (
    <li className="standard-list__item">
      <div className="flex items-center">
        <label htmlFor="" className="leading-[1.6rem]">
          Automatically accept invoices if within a specified amount of quoted
          price
        </label>
        <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
        <div>
          <FvButton
            icon="pen-alt"
            className="border-fv-blue bg-fv-blue-100 relative right-[.35rem] ml-2 flex h-4 w-4 items-center justify-center rounded-full border-2 p-4"
            onClick={() => setIsOpen(true)}
          />

          <AuditThresholdSlider
            isOpen={isOpen}
            close={() => setIsOpen(false)}
          />
        </div>
      </div>
      <div className="border-t-fv-gray my-4 h-px border-t border-dashed" />

      <ul className="standard-list standard-list--fcp-0 standard-list--lcb-0">
        {enabledWorkflows.map(w => (
          <WorkflowRow workflow={w} key={w} setIsOpen={setIsOpen} />
        ))}
      </ul>
    </li>
  )
}

type RowProps = {
  workflow: Workflow
  setIsOpen: (isOpen: boolean) => void
}

const WorkflowRow = (props: RowProps) => {
  const { invoicing } = useAccountSettings()
  const threshold = invoicing?.automation?.[props.workflow]
  const enabled = !!threshold?.enabled
  const thresholdLabel = threshold?.type === 'flat' ? ' USD' : '%'
  const icon: IconName =
    props.workflow === 'parcel'
      ? 'box'
      : props.workflow === 'ltl'
        ? 'truck'
        : 'truck-moving'

  return (
    <li className="standard-list__item standard-list__item--dashed flex items-center gap-x-4">
      <div className="flex items-center">
        <button onClick={() => props.setIsOpen(true)}>
          <Icon icon={icon} className="fa-fw mr-1" />{' '}
          {displayWorkflow(props.workflow)}
        </button>
        <div className="border-fv-blue mx-2 h-px w-4 border-t border-dashed" />
        <span className="text-sm">
          {enabled
            ? `Enabled for invoices within ${threshold.threshold}${thresholdLabel} of quoted amount.`
            : 'Automated auditing disabled'}
        </span>
      </div>
    </li>
  )
}
