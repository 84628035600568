export const DateRangeNames = [
  'this-week',
  'next-week',
  'this-month',
  'this-year',
  'last-7-days',
  'last-30-days',
  'last-month',
  'last-year',
  'yesterday',
  'tomorrow',
  'today',
] as const

export type DateRange = (typeof DateRangeNames)[number]
