import { nextBusinessDay } from '@freightview/holidays'
import dayjs from 'dayjs'
import sumBy from 'lodash/sumBy'
import pluralize from 'pluralize'

import {
  type FullShipment,
  type ListShipment,
  type LoadDocument,
  type LoadHandlingUnit,
  type LocationType,
  type Mode,
  type ProcessingStatus,
  type ShipmentLocation,
  type UIQuote,
} from '@fv/client-types'
import { type AddressBase } from '@fv/models'

import { type BookFormLoad } from '../features/booking/types'
import { quoteFuncs } from '../features/quote/quoteFuncs'
import { type QuoteFormLoad } from '../features/quote-request/types'
import { type FormLocation } from '../types/FormLocation'
import { isPuertoRicanLoc } from './locationFuncs'

type LocContainer = {
  locations: Partial<AddressBase>[]
}

export function isInternational({ locations }: LocContainer) {
  const originCountry = locations[0].country
  return !!locations.find(x => x.country !== originCountry)
}

function getInternationalLocation<
  T extends Pick<ShipmentLocation, 'type' | 'country'>,
>(origin: T | undefined, locations: Array<T>): T | undefined {
  return locations.find(x => x.country !== origin?.country)
}

export function getCustomsBrokerLocationContext<
  T extends Pick<ShipmentLocation, 'type' | 'country' | 'state'>,
>(locations: Array<T>) {
  const origin = getOrigin({ locations })
  const international = getInternationalLocation(origin, locations)
  let location = international
  if (locations.some(isPuertoRicanLoc)) {
    location = locations.find(l => l.state !== origin?.state)
  }
  return {
    required: !!international,
    location,
  }
}

export function isMexican({ locations }: { locations: AddressBase[] }) {
  return !!locations.find(x => x.country === 'mx')
}

export function isCanadian({ locations }: LocContainer) {
  return {
    hasCanadianStop: !!locations.find(x => x.country === 'ca'),
    isCanadianOrigin: locations[0].country === 'ca',
  }
}

export function isPuertoRican({ locations }: Pick<ListShipment, 'locations'>) {
  const origin = getOrigin({ locations })
  return {
    hasPuertoRicanStop: locations.some(l => isPuertoRicanLoc(l)),
    isPuertoRicanOrigin: !!origin && isPuertoRicanLoc(origin),
  }
}

export const composeCityState = (loc?: ShipmentLocation) => {
  if (!loc) return 'Unknown'
  return `${loc.city}, ${loc.state}`
}

export const composeShipmentLocationSummary = (
  locations: FullShipment['locations'],
) => {
  const origin = getOrigin({ locations })
  const dest = getDestination({ locations })

  return `${composeCityState(origin)} to ${composeCityState(dest)}`
}

export function composeCarrierBolUrl(
  shipment?: Pick<ListShipment, 'documents'>,
) {
  const carrierBol = shipment?.documents?.reduce(
    (bol, doc) => {
      const isCarrierBol = doc.source === 'carrier' && doc.type === 'bol'

      if (!isCarrierBol) return bol
      if (!bol) return doc

      return new Date(bol.uploadDate) < new Date(doc.uploadDate) ? doc : bol
    },
    null as LoadDocument | null,
  )

  return carrierBol?.url
}

export function composeFvBolUrl(
  shipment?: Pick<ListShipment, 'documents' | 'isLegacy'>,
) {
  const { documents, isLegacy } = shipment ?? {}
  const bol = documents?.find(d => d.type === 'bol' && d.source === 'system')
  if (!bol || !isLegacy) return bol?.url

  // Stop gap until we're able to run migration to update these URLs
  return bol.url.replace('/pdf', '/internal')
}

export const isBolProcessing = (
  shipment?: Pick<ListShipment, 'bol' | 'status'>,
) => {
  const { bol, status } = shipment ?? {}
  if (status === 'canceled') return false
  return bol?.status === 'pending' || bol?.status === 'requesting'
}

export const isProcessingQuotes = (
  shipment?: Pick<FullShipment, 'quoteProcessStatus'>,
) => {
  return (
    shipment?.quoteProcessStatus === 'requesting' ||
    shipment?.quoteProcessStatus === 'rerating'
  )
}

export function mapPaymentTerms({
  selectedQuote,
}: Pick<ListShipment, 'selectedQuote'>) {
  if (!selectedQuote) return

  switch (selectedQuote.paymentTerms) {
    case 'inbound-collect':
      return 'Inbound collect'
    case 'outbound-collect':
      return 'Outbound collect'
    case 'outbound-prepaid':
      return 'Outbound prepaid'
    case 'third-party-collect':
      return 'Third party collect'
    case 'third-party-prepaid':
      return 'Third party prepaid'
    default:
      return
  }
}

export function getCarrierName({
  selectedQuote,
}: Pick<ListShipment, 'selectedQuote'>) {
  if (!selectedQuote) return
  const carrier = selectedQuote.assetCarrierName ?? selectedQuote.providerName
  return carrier.trim()
}

export function getCarrierDetails(
  {
    selectedQuote,
  }: {
    selectedQuote?: Pick<
      UIQuote,
      'assetCarrierName' | 'providerName' | 'serviceDescription'
    >
  },
  includeService = true,
) {
  if (!selectedQuote) return

  const { assetCarrierName, providerName, serviceDescription } = selectedQuote
  const carrierName = assetCarrierName || providerName
  let carrierDetails = carrierName

  if (!carrierName) return

  if (includeService && serviceDescription) {
    carrierDetails += ` ${serviceDescription}`
  }

  if (providerName && providerName !== carrierName) {
    carrierDetails += ` via ${providerName}`
  }

  return carrierDetails
}

// TODO: update for LTL/Parcel
export function mapRateType({
  selectedQuote,
  isFreightCollect,
}: Pick<ListShipment, 'isFreightCollect' | 'selectedQuote'>) {
  if (!selectedQuote) return

  if (isFreightCollect) return 'Customer Routed'
  return selectedQuote?.pricingMethod === 'contracted'
    ? 'Contracted rate'
    : 'Spot quote'
}

export function getFirstSequenceOfType(
  locations: Pick<ShipmentLocation, 'sequence' | 'stopType'>[],
  stopType: 'pickup' | 'delivery',
) {
  const location = locations.find(x =>
    stopType === 'pickup' ? x.stopType !== 'delivery' : x.stopType !== 'pickup',
  )

  if (!location) {
    return stopType === 'pickup' ? 0 : locations.length - 1
  }

  return location.sequence
}

export function getItemsForSequence(
  sequence: number,
  items?: LoadHandlingUnit[],
): LoadHandlingUnit[] {
  if (!items) return []

  const itemsForSequence = items.filter(
    i =>
      i.pickupLocationSequence === sequence ||
      i.dropLocationSequence === sequence,
  )

  // Sort deliveries to front
  return itemsForSequence.sort(a => {
    if (a.pickupLocationSequence === sequence) return 1
    if (a.dropLocationSequence === sequence) return -1
    return 0
  })
}

export function resequenceFormItems<T extends BookFormLoad | QuoteFormLoad>(
  loads: T[],
  stops: Array<FormLocation>,
  invalidSequence: number,
  isAdd = false,
): T[] {
  return loads.map(x => ({
    ...x,
    items: x.items.map(i => {
      let pickSequence = i.pickSequence
      let dropSequence = i.dropSequence

      if (pickSequence === invalidSequence) {
        const pickupOpts = stops
          .slice(0, isAdd ? pickSequence - 1 : pickSequence)
          .reverse()
        pickSequence = getFirstSequenceOfType(pickupOpts, 'pickup')
      }

      if (dropSequence === invalidSequence) {
        const deliveryOpts = stops.slice(
          isAdd ? dropSequence + 1 : dropSequence,
        )
        dropSequence = getFirstSequenceOfType(deliveryOpts, 'delivery')
      }

      if (dropSequence > stops.length - 1) {
        dropSequence = stops.length - 1
      }

      return {
        ...i,
        pickSequence,
        dropSequence,
      }
    }),
  }))
}

export function defaultStopDate(
  type: LocationType,
  currentDate?: Date | null,
): Date | null {
  if (currentDate && dayjs.utc(currentDate).isAfter(dayjs.utc().startOf('d'))) {
    return currentDate
  }

  return type === 'origin' ? nextBusinessDay() : null
}

export function canConfirmShipment({
  pickup,
  status,
  selectedQuote,
}: Pick<FullShipment, 'pickup' | 'status' | 'selectedQuote'>): boolean {
  if (!quoteFuncs.isSpotWorkflow(selectedQuote)) {
    return false
  }

  return status === 'awarded' && pickup?.status === 'pending'
}

export function originStopDate({
  locations,
}: {
  locations: Array<Pick<ShipmentLocation, 'type' | 'stopDate'>>
}) {
  return locations.find(loc => loc.type === 'origin')?.stopDate
}

export function totalPieceCount({
  items,
}: Pick<FullShipment, 'items'>): number {
  return (
    items?.reduce((prev, cur) => {
      const total = sumBy(cur.contains, c => c.quantity ?? 1) || cur.quantity

      return prev + total
    }, 0) ?? 0
  )
}

export function getOrigin<T extends Pick<ShipmentLocation, 'type'>>({
  locations,
}: {
  locations: Array<T>
}): T | undefined {
  return locations.find(loc => loc.type === 'origin')
}

export function getDestination<T extends Pick<ShipmentLocation, 'type'>>({
  locations,
}: {
  locations: Array<T>
}): T | undefined {
  return locations.find(loc => loc.type === 'destination')
}

export function getItemDescription({
  items,
  equipment,
  workflow,
}: Pick<ListShipment, 'equipment' | 'items' | 'workflow'>) {
  if (workflow === 'truckload') {
    return equipment.description
  }
  if (!items.length) {
    return ''
  }
  const firstItem = items[0]
  let description = firstItem.description
    ? firstItem.description
    : `${firstItem.quantity} ${pluralize(firstItem.type, firstItem.quantity)}`
  if (items.length > 1) {
    description += ` & ${items.length - 1} other ${pluralize(
      'item',
      items.length - 1,
    )}`
  }
  return description
}

export function isBooked({ status }: Pick<FullShipment, 'status'>) {
  return status !== 'pending' && status !== 'canceled'
}

function isStatusOkOrInProgress(s?: ProcessingStatus) {
  const values: ProcessingStatus[] = ['ok', 'requesting', 'pending']
  return s && values.includes(s)
}

export function isBookedAndDispatched({
  status,
  pickup,
  bol,
}: Pick<FullShipment, 'status' | 'pickup' | 'bol'>) {
  return (
    isBooked({ status }) &&
    (isStatusOkOrInProgress(pickup?.status) ||
      isStatusOkOrInProgress(bol?.status))
  )
}

export const modeNames: Partial<Record<Mode, string>> = {
  intermodal: 'Intermodal',
  ltl: 'LTL',
  parcel: 'Parcel',
  truckload: 'Truckload',
}
