import { type UserPermissionType } from '@fv/client-types'
import { type Country } from '@fv/models'

export const basePath = '/analytics'
export const lanes = 'lanes'
export const providers = 'providers'
export const spotQuote = 'spot-quote'
export const users = 'users'
export const subViews = [
  'cost-per-cwt',
  'spend',
  'volume',
  'weight',
  'win-rate',
  'response-time',
] as const

export type AnalyticsView =
  | typeof lanes
  | typeof providers
  | typeof spotQuote
  | typeof users
export type AnalyticsSubView = (typeof subViews)[number]
export const defaultSubView = subViews[1]

export type RouteParams = {
  subView?: AnalyticsSubView
  view?: AnalyticsView
}

export interface AnalyticsPageParams extends RouteParams {
  pickupDate: string | null
  qs: string
}

export type Lane = {
  city: string
  count: number
  country: Country
  lat: number
  lng: number
  postalCode: string
  spend: number
  state: string
  weight: number
}

export type LaneAnalyticsDTO = {
  lanes: Lane[]
  shipmentCount: number
}

export type Provider = {
  _id: string
  costPerCwt: number
  count: number
  providerName: string
  spend: number
  weight: number
}

export type ProviderAnalyticsDTO = {
  providerCount: number
  providers: Provider[]
  realizedSavings: number
  shipmentCount: number
  totalSpend: number
  unrealizedSavings: number
}

export type SpotQuoteAnalyticsDTO = {
  carrier: string
  declinedCount: number
  quoteCount: number
  responseTime: number
  totalCount: number
  winCount: number
}

export type SpotQuoteChartProps = {
  data: SpotQuoteAnalyticsDTO[]
}

export type UserStatsDTO = {
  userId: string
  parcelBooks: number
  parcelQuotes: number
  ltlBooks: number
  ltlQuotes: number
  tlBooks: number
  tlQuotes: number
  firstName: string
  lastName: string
  email: string
  permissions: UserPermissionType[]
  lastLogin: Date
  lastQuoteDate: Date
  lastBookDate: Date
  displayName: string
}

export type UserAnalyticsDTO = {
  user: UserStatsDTO[]
}
