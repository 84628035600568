import { type UseMutateFunction } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'

import {
  ClipboardButton,
  FvButton,
  FvLinkButton,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

type Props = {
  defaultValue?: string
  label: string
  loading: boolean
  name: string
  onSave: UseMutateFunction<ListShipment, unknown, string, unknown>
  readOnly?: boolean
  className?: string
  formClassName?: string
}

export const NOT_AVAILABLE = 'NA'

export const EditableRefNum = ({
  defaultValue = '',
  label,
  loading,
  name,
  onSave,
  readOnly = false,
  className,
  formClassName,
}: Props) => {
  const [isEditing, setEditing] = useState(false)
  const textDisplay = `${label} ${defaultValue || NOT_AVAILABLE}`

  function toggleEditing() {
    // Closing while loading can lead to confusing UI state
    setEditing(wasEditing => (loading ? wasEditing : !wasEditing))
  }

  function onValidSubmit(form: HTMLFormElement) {
    if (loading) return

    const input = form.elements.namedItem(name) as HTMLInputElement
    const value = input.value.trim()

    if (value === defaultValue) toggleEditing()
    else onSave(value, { onSuccess: toggleEditing })
  }

  return (
    <div
      className={clsx(
        className,
        'flex items-center gap-x-2 b1350:flex-nowrap',
        {
          'flex-wrap': isEditing,
        },
      )}
    >
      {readOnly ? (
        <span>{textDisplay}</span>
      ) : (
        <>
          <FvLinkButton
            className="break-all"
            theme="underlined"
            onClick={toggleEditing}
            title="Edit number"
          >
            {textDisplay}
          </FvLinkButton>
          <ClipboardButton value={defaultValue} />
        </>
      )}

      {isEditing && (
        <ValidatedForm
          className={`${formClassName} input-group mb-4 mt-2 basis-full b1350:m-0 b1350:w-64`}
          onValidSubmit={onValidSubmit}
        >
          <TextField
            autoFocus
            className="form-control"
            defaultValue={defaultValue}
            name={name}
            readOnly={loading}
          />
          <div className="input-group__append">
            <FvButton
              theme="default"
              icon="check"
              loading={loading}
              type="submit"
            >
              Save
            </FvButton>
            <FvButton
              icon="times"
              onClick={toggleEditing}
              theme="plain"
              className="pr-0"
            />
          </div>
        </ValidatedForm>
      )}
    </div>
  )
}
