import clsx from 'clsx'
import { useState } from 'react'

import {
  type FullShipment,
  type HoldAtLocation,
  type ShipmentLocation,
} from '@fv/client-types'

import { type TerminalAndType } from '../../features/quote/useTerminals'
import { LegLoad } from './LegLoad'
import { StopCard } from './StopCard'
import { type LegItem } from './types'

type RouteLegProps = {
  destination: ShipmentLocation
  items: LegItem[]
  origin: ShipmentLocation
  holdAtLocation?: HoldAtLocation
  load: FullShipment
  terminals?: TerminalAndType[]
}

export const RouteLeg = ({
  destination,
  items,
  origin,
  holdAtLocation,
  load,
  terminals,
}: RouteLegProps) => {
  const [expanded, setExpanded] = useState(false)
  const hasItems = !!items.length
  const toggleExpanded = () => setExpanded(p => !p)

  return (
    <div className="shipment-details-route-ctn mb-4">
      <div
        className={clsx('shipment-details-route flex', {
          'mb-4': hasItems,
        })}
      >
        <StopCard
          expanded={expanded}
          isOrigin
          location={origin}
          toggleExpanded={toggleExpanded}
          workflow={load.workflow}
          terminal={
            origin.type === 'origin'
              ? terminals?.find(t => t.type === 'origin terminal')
              : undefined
          }
        />
        <StopCard
          expanded={expanded}
          location={destination}
          toggleExpanded={toggleExpanded}
          workflow={load.workflow}
          holdAtLocation={holdAtLocation}
          terminal={
            destination.type === 'destination'
              ? terminals?.find(t => t.type === 'destination terminal')
              : undefined
          }
        />
      </div>

      {hasItems && (
        <ol className="standard-list standard-list--lcb-0">
          {items.map(i => (
            <LegLoad
              className={`route-color-${destination.type}`}
              item={i}
              key={i._id}
              load={load}
            />
          ))}
        </ol>
      )}
    </div>
  )
}
