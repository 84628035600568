import clsx from 'clsx'
import { type ParsedQuery } from 'query-string'
import { useRef, useState } from 'react'

import { FvButton, Icon, LoadMore, SliderPanel } from '@fv/client-components'
import { flattenPages } from '@fv/client-core'

import ExportOptions from '../../components/ExportOptions'
import LoadDetailPanel from '../../components/list/LoadDetailPanel'
import TopNav from '../../components/TopNav'
import { useUserSettings } from '../../hooks/settings'
import { useArchiveLoads } from '../../hooks/shipments'
import { ScheduledReportsPanel } from '../scheduled-reports/ScheduledReportsPanel'
import { CompactFilterBar } from '../shipment-filters/CompactFilterBar'
import { ConfirmedFiltersProvider } from '../shipment-filters/ConfirmedFiltersProvider'
import { ShipmentFilterPanel } from '../shipment-filters/FilterPanel'
import { useHandleFilterChange } from '../shipment-filters/hooks'
import {
  isDefaultFilter,
  useConfirmedShipments,
} from './hooks/useConfirmedShipments'
import {
  useConfirmedListParams,
  useSetActiveLoadId,
} from './hooks/useShipmentListParams'
import { CondensedViewToggle } from './CondensedViewToggle'
import { ShipmentCardList } from './ShipmentCardList'
import { ShipmentTable } from './ShipmentTable'
import { SortDirectionToggle } from './SortDirectionToggle'

export const ConfirmedShipmentList = () => {
  const listRef = useRef<HTMLDivElement>(null)
  const shipmentsQuery = useConfirmedShipments()
  const settingsQuery = useUserSettings()
  const { archive, isArchiving } = useArchiveLoads('confirmed')
  const { activeLoadId, qs, sortBy, isArchived, sortDirection } =
    useConfirmedListParams(flattenPages(shipmentsQuery.data?.pages))
  const setActiveLoadId = useSetActiveLoadId()
  const { handle: handleChange } = useHandleFilterChange(listRef)
  const [isExporting, setIsExporting] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [isScheduleOpen, setScheduleOpen] = useState(false)

  const [condensed, setCondensed] = useState(
    settingsQuery.data?.preferences?.['shipment-list-condensed'],
  )
  const showArchive = !!selected.length

  const handleFilterChange = (data: ParsedQuery) => {
    setSelected([])
    handleChange({ ...data, sortBy, sortDirection })
  }

  const handleArchiveClick = async () => {
    if (
      !window.confirm(
        `Are you sure you want to ${
          isArchived ? 'unarchive' : 'archive'
        } these shipments?`,
      )
    ) {
      return
    }
    await archive({
      loadIds: selected,
      shouldArchive: !isArchived,
    })
    setSelected([])
  }

  return (
    <ConfirmedFiltersProvider>
      <div
        className={clsx(
          'grid-tl-shipment-list grid bg-[#f2f2f2] h-screen overflow-hidden',
          condensed
            ? 'condensed grid-cols-[19rem_1fr_0] grid-rows-[auto_1fr_0]'
            : 'grid-cols-[19rem_1fr_30.5rem] grid-rows-[auto_1fr_0]',
          showArchive && condensed && 'archive !grid-rows-[auto_1fr_5rem]',
        )}
      >
        <TopNav />
        <ShipmentFilterPanel
          onChange={handleFilterChange}
          onScheduleOpen={() => setScheduleOpen(true)}
        />
        <div
          className={clsx(
            'shipment-list-ready b1400:col-span-2',
            condensed && 'col-span-full',
          )}
          id="shipmentList"
          ref={listRef}
        >
          <nav className="mb-8">
            <div className="divided-content divided-content--end divided-content--start flex flex-wrap items-center">
              {!condensed ? (
                <div>
                  <span>
                    <SortDirectionToggle sortDirection={sortDirection} />{' '}
                    <span>Sort:</span>
                  </span>

                  <FvButton
                    icon="calendar-day"
                    className={clsx('ml-4', {
                      'active-hint-circle': sortBy === 'pickupDate',
                    })}
                  >
                    Pickup date
                  </FvButton>
                </div>
              ) : (
                <div className="flex-1">
                  <CompactFilterBar onChange={handleFilterChange} />
                </div>
              )}

              <FvButton
                icon="file-export"
                className={clsx(
                  condensed &&
                    'b1650:border-l-fv-gray b1650:border-l b1650:pl-4',
                )}
                onClick={() => {
                  setIsExporting(true)
                }}
              >
                Export list
              </FvButton>
              <CondensedViewToggle
                listRef={listRef}
                checked={condensed}
                onChange={setCondensed}
              />
            </div>
            {!condensed && (
              <div className="b1400:block border-t-fv-gray mt-4 hidden basis-full border-l-0 border-t border-dashed pl-0 pt-4">
                <CompactFilterBar onChange={handleFilterChange} />
              </div>
            )}
          </nav>

          {condensed ? (
            <>
              <hr className="-mt-4" />
              <ShipmentTable
                query={shipmentsQuery}
                onActivate={l => setActiveLoadId(l.loadId)}
                selected={selected}
                setSelected={setSelected}
              />
            </>
          ) : (
            <ShipmentCardList
              query={shipmentsQuery}
              onActivate={l => setActiveLoadId(l.loadId)}
            />
          )}
          <LoadMore
            className="mt-4"
            fetchNextPage={shipmentsQuery.fetchNextPage}
            hasNextPage={shipmentsQuery.hasNextPage}
            isLoading={shipmentsQuery.isFetchingNextPage}
          />
        </div>

        {!condensed && (
          <LoadDetailPanel key={activeLoadId} loadId={activeLoadId} />
        )}
        {showArchive && condensed && (
          <div className="footer-actions col-span-full">
            <FvButton
              theme="default"
              loading={isArchiving}
              icon="archive"
              onClick={handleArchiveClick}
            >
              {isArchived ? `Unarchive` : `Archive`} {selected.length} shipments
            </FvButton>
          </div>
        )}
      </div>

      <ExportOptions
        closePanel={() => setIsExporting(false)}
        isOpen={isExporting}
        queryString={isDefaultFilter(qs) ? `isArchived=false&${qs}` : qs}
      />
      <SliderPanel
        width="54em"
        isOpen={isScheduleOpen}
        closePanel={() => setScheduleOpen(false)}
      >
        {isScheduleOpen && (
          <ScheduledReportsPanel onClose={() => setScheduleOpen(false)} />
        )}
      </SliderPanel>
    </ConfirmedFiltersProvider>
  )
}
