import sumBy from 'lodash/sumBy'
import { useState } from 'react'

import { type FullShipment, type ShippingLabelSize } from '@fv/client-types'

import {
  composeShipmentLocationSummary,
  totalPieceCount,
} from '../../utils/shipmentFuncs'
import { type ShipmentContentProps } from '../shipment-details-panel/types'
import { useLabelSettings } from './hooks'
import { labelOptions } from './labelConfig'
import { LabelOption } from './LabelOption'

const rollLabels = labelOptions.filter(o => o.type === 'roll')
const sheetLabels = labelOptions.filter(o => o.type === 'sheet')

export type ShipmentLabelsProps = Omit<ShipmentContentProps, 'load'> & {
  load: Pick<
    FullShipment,
    'bol' | 'items' | 'loadId' | 'locations' | 'workflow'
  >
  onSubmit?: (props: {
    count: number
    loadId: string
    size: ShippingLabelSize
    start?: number
  }) => void
}

export const ShipmentLabels = ({
  load,
  onSubmit,
  setActiveSlider,
}: ShipmentLabelsProps) => {
  const { bol, items, loadId, locations } = load
  const settings = useLabelSettings(load)
  const [activeLabel, setActiveLabel] = useState<ShippingLabelSize>(
    settings.labelSize,
  )

  const bolNumber = bol?.bolNumber
  const closePanel = () => setActiveSlider(undefined)
  const pieceQuantity = totalPieceCount({ items })
  const routeLabel = composeShipmentLocationSummary(locations)
  const unitQuantity = sumBy(items, 'quantity') || 1

  return (
    <div className="flex h-full flex-col items-stretch">
      <div>
        Labels for {bolNumber && `BOL# ${bolNumber} - `}
        {routeLabel}
      </div>
      <hr className="mx-0 my-2 mb-4" />
      <div className="mb-2 flex flex-1 items-stretch overflow-auto">
        <div className="flex-1">
          <h6 className="mb-0">Sheet labels</h6>
          <hr className="mx-0 my-2 mb-4" />
          <ul className="standard-list standard-list--lcb-0 shipping-label-icon-ctn">
            {sheetLabels.map(o => (
              <LabelOption
                active={o.size === activeLabel}
                closePanel={closePanel}
                config={o}
                defaultCount={settings.labelCount}
                key={o.size}
                loadId={loadId}
                pieceQuantity={pieceQuantity}
                setActive={setActiveLabel}
                unitQuantity={unitQuantity}
                onSubmit={onSubmit}
              />
            ))}
          </ul>
        </div>
        <div className="divider mx-8 my-0" />
        <div className="flex-1">
          <h6 className="mb-0">Roll labels</h6>
          <hr className="mx-0 my-2 mb-4" />
          <ul className="standard-list standard-list--lcb-0 shipping-label-icon-ctn flex-1">
            {rollLabels.map(o => (
              <LabelOption
                active={o.size === activeLabel}
                closePanel={closePanel}
                config={o}
                defaultCount={settings.labelCount}
                key={o.size}
                loadId={loadId}
                pieceQuantity={pieceQuantity}
                setActive={setActiveLabel}
                unitQuantity={unitQuantity}
                onSubmit={onSubmit}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
