import clsx from 'clsx'
import { type ChangeEvent } from 'react'

import { CheckboxField, Icon, TextAreaField } from '@fv/client-components'
import {
  addressFuncs,
  validateEmailInput,
  validateEmailListInput,
} from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type QuoteStopType,
} from '@fv/client-types'

import { AddressInput } from '../../components/inputs/AddressInput'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { ShipTypeSelector } from '../../components/inputs/ShipTypeSelector'
import { type FormSectionProps } from '../../components/inputs/types'
import { PillHeader } from '../../components/PillHeader'
import { AddressBookButton } from '../addresses/AddressBookButton'
import { addressMapper } from '../addresses/addressFuncs'
import { LocationAccessorialForm } from '../quote/LocationAccessorialForm'
import { useAccountSettings } from '../settings/account-settings/hooks'
import { useCarrierBookingFormSettings } from './bookFuncs'
import { useBookingFormCtx } from './BookingFormProvider'
import { DockTimeInput } from './DockTimeInput'
import { ShareShipmentWith } from './ShareShipmentWith'

type LocationField =
  | 'address'
  | 'address2'
  | 'company'
  | 'contactEmail'
  | 'contactName'
  | 'contactPhone'
  | 'instructions'
  | 'refNum'
  | 'shares'
  | 'shipType'

type LocationFormProps = FormSectionProps & {
  type: QuoteStopType
}

const mapCompanyName = (x: { company?: string }) => x.company ?? 'NA'

export const BookingFormLocation = ({ disabled, type }: LocationFormProps) => {
  const accountSettings = useAccountSettings()
  const { setLocationValues, toggleEquipmentAccessorial } = useBookingFormCtx(
    s => s.actions,
  )

  const location = useBookingFormCtx(s =>
    s.locations.find(x => x.type === type),
  )
  const requireEmail =
    useCarrierBookingFormSettings()?.requiresOriginEmail && type === 'origin'
  const equipmentAccessorials = useBookingFormCtx(s => s.equipmentAccessorials)
  const workflow = useBookingFormCtx(s => s.workflow)
  const locAccessorials = location?.accessorials.map(a => a.key) ?? []
  const contactPhoneRequired =
    type === 'origin' ||
    location?.shipType === 'residential' ||
    locAccessorials.includes('notify') ||
    locAccessorials.includes('schedule')

  if (!location) return null

  const isLtl = workflow === 'ltl'
  const isOrigin = type === 'origin'
  const stopTypeDisplay = isOrigin ? 'pickup' : 'delivery'

  function getInputProps(name: LocationField, props?: any) {
    return {
      disabled,
      name: `${type}-${name}`,
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (name === 'contactEmail') {
          validateEmailInput(e.target as HTMLInputElement)
        }

        if (name === 'shares') {
          validateEmailListInput(e.target as HTMLInputElement)
        }

        setLocationValues(type, { [name]: e.target.value })
      },
      value: location?.[name] ?? '',
      ...props,
    }
  }

  function selectAddressBookEntry(address: ClientAddressLookupResult | null) {
    if (!address) setLocationValues(type, { company: '' })

    const locationValues = addressMapper.dtoToFormLocation(address, workflow)

    setLocationValues(type, {
      ...locationValues,
      copyBOL: !!address?.sendBOL,
      shares: (address?.bolFollowers ?? []).join(', '),
    })
  }

  function selectAtlasAddress(atlasAddress: ClientAddressLookupResult | null) {
    if (!atlasAddress) {
      setLocationValues(type, {
        accountLocationId: '',
        city: '',
        country: 'us',
        lat: 0,
        lng: 0,
        postalCode: '',
        shipType: 'business dock',
        state: '',
        timezone: '',
      })
    } else {
      const { address, address2, shipType, accessorials, ...values } =
        addressMapper.dtoToFormLocation(atlasAddress, workflow)

      setLocationValues(type, {
        ...values,
        accountLocationId: '',
      })
    }
  }

  return (
    <div
      className={clsx('parcel-ltl-booking-location mb-12 border-[.115rem]', {
        'border-[#7ad04f]': !isOrigin,
        'border-[#ff9a59]': isOrigin,
      })}
    >
      <PillHeader className="beer-container">
        <Icon
          className={clsx({
            'color-origin': isOrigin,
            'color-destination': !isOrigin,
          })}
          icon="map-marker"
        />
        <span>{isOrigin ? 'Pick up' : 'Deliver'}</span>
      </PillHeader>

      <div className="form-row">
        <InputGroupWrapper
          className="b1450:basis-4/12 basis-4/12"
          label="Company"
          name={`${type}-company`}
          required
        >
          <div className="input-group__prepend">
            <AddressBookButton
              context={workflow}
              disabled={disabled}
              setAddress={selectAddressBookEntry}
            />
          </div>
          <AddressInput
            context={workflow}
            allowFreeText
            formatAddress={mapCompanyName}
            formatOptionLabel={addressFuncs.companyAddress}
            includeAddressBook
            includeAtlas={false}
            isClearable={false}
            location={location}
            name={`${type}-company`}
            onInputChange={company => setLocationValues(type, { company })}
            onLocationChange={selectAddressBookEntry}
            placeholder="Company name"
            required
          />
        </InputGroupWrapper>

        <InputGroup
          className="b1450:basis-4/12 b1600:basis-4/12 basis-3/12"
          inputProps={getInputProps('address')}
          inputType="text"
          label="Street address"
          required
        />

        <InputGroup
          className="b1600:basis-4/12 b1450:basis-4/12 basis-2/12"
          inputProps={getInputProps('address2', { placeholder: 'Address 2' })}
          inputType="text"
          label="Address 2"
        />

        <InputGroupWrapper
          className="b1600:basis-4/12 basis-3/12"
          label="City, state & zip code"
          name={`${type}-cityStateZip`}
          required
        >
          <AddressInput
            formatAddress={addressFuncs.cityStateZip}
            isClearable={false}
            location={location}
            name={`${type}-cityStateZip`}
            onLocationChange={selectAtlasAddress}
            placeholder="Zip/postal code"
            required
          />
        </InputGroupWrapper>

        {isLtl && (
          <ShipTypeSelector
            name={`${type}-shipType`}
            value={location}
            onChange={({ shipType, accessorials }) => {
              setLocationValues(type, { shipType, accessorials })
            }}
            showDetails
            showLabels
            className="basis-4/12"
          />
        )}

        <InputGroup
          className="b1650:basis-2/12 b1600:basis-4/12 basis-3/12"
          inputProps={getInputProps('contactName')}
          inputType="text"
          label="Contact name"
          required
        />

        <ContactPhoneInput
          className="b1650:basis-3/12 b1600:basis-4/12 b1350:basis-3/12 b1300:basis-4/12 b1200:basis-4/12 basis-2/12"
          disabled={disabled}
          name={`${type}-contactPhone`}
          onChange={values => setLocationValues(type, values)}
          required={contactPhoneRequired}
          values={location}
        />

        <InputGroup
          className="b1600:basis-4/12 b1350:basis-3/12 b1300:basis-8/12 b1200:basis-4/12 basis-3/12"
          inputProps={getInputProps('contactEmail')}
          inputType="text"
          required={requireEmail}
          label="Contact email"
        />

        {(workflow === 'ltl' || isOrigin) && (
          <>
            <DockTimeInput
              className="ready-close-time b1350:basis-3/12 b1200:basis-2/12 basis-2/12"
              label="Ready time"
              value={location}
              name="opensAt"
              onChange={values => setLocationValues(type, values)}
              required={isOrigin}
            />

            <DockTimeInput
              className="ready-close-time b1350:basis-3/12 b1200:basis-2/12 basis-2/12"
              label="Close time"
              value={location}
              name="closesAt"
              onChange={values => setLocationValues(type, values)}
              required={isOrigin}
            />
          </>
        )}

        <InputGroup
          className="b1600:basis-full b1300:flex-1 flex-1"
          inputProps={getInputProps('refNum')}
          inputType="text"
          required={
            isOrigin
              ? accountSettings.booking?.shipperNumberRequired
              : accountSettings.booking?.poNumberRequired
          }
          label={isOrigin ? "Shipper's #" : 'Purchase order #'}
        />
      </div>

      <div className="w-full">
        <LocationAccessorialForm
          value={location}
          workflow={workflow}
          onChange={update => setLocationValues(type, update)}
        />

        {!isOrigin && isLtl && (
          <CheckboxField
            checked={equipmentAccessorials.some(
              a => a === 'protect-from-freezing',
            )}
            className="checkbox checkbox--vertical mb-3"
            disabled={disabled}
            label="Protect from freezing"
            name={`${type}-protect-from-freezing`}
            onChange={() => toggleEquipmentAccessorial('protect-from-freezing')}
          />
        )}
      </div>

      <ShareShipmentWith
        location={location}
        onChange={({ copyBOL, shares }) =>
          setLocationValues(type, { copyBOL, shares })
        }
      />

      <TextAreaField
        {...getInputProps('instructions', {
          className: 'form-control h-auto mt-4',
          placeholder: `Other ${stopTypeDisplay} instructions`,
          rows: 3,
        })}
      />
    </div>
  )
}
