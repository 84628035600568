import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'

import {
  type AnalyticsPageParams,
  basePath,
  defaultSubView,
  lanes,
  providers,
  type RouteParams,
  spotQuote,
  subViews,
  users,
} from './types'

export function useAnalyticsParams() {
  const { pathname, search } = useLocation()
  const { subView, view } = useParams<RouteParams>()
  const navigate = useFvNavigate()

  useEffect(() => {
    const isValidView =
      view === lanes ||
      view === providers ||
      view === spotQuote ||
      view === users
    const isValidSubView = !subView || subViews.includes(subView)
    const params = new URLSearchParams(search)
    const prevQueryString = params.toString()

    if (!params.get('pickupDate')) {
      params.set('pickupDate', 'last-7-days')
    }

    if (
      !isValidView ||
      !isValidSubView ||
      params.toString() !== prevQueryString
    ) {
      let path = isValidView ? pathname : `${basePath}/${providers}`
      if (!isValidSubView) path = path.replace(subView, defaultSubView)
      navigate(`${path}?${params.toString()}`, { replace: true })
    }
  }, [navigate, pathname, search, subView, view])

  return useMemo(() => {
    const params = new URLSearchParams(search)

    return {
      pickupDate: params.get('pickupDate'),
      qs: params.toString(),
      subView,
      view,
    } as AnalyticsPageParams
  }, [search, subView, view])
}
