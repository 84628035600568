import clsx from 'clsx'
import { type Dispatch, type SetStateAction } from 'react'

import { Icon } from '@fv/client-components'

type Props = {
  closePanel: () => void
  isViewingSentList: boolean
  setViewingSentList: Dispatch<SetStateAction<boolean>>
}

const PanelNav = ({
  closePanel,
  isViewingSentList,
  setViewingSentList,
}: Props) => {
  return (
    <nav className="divided-content divided-content--start shipment-list-ancillary-details__nav">
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          closePanel()
        }}
      >
        <Icon icon="arrow-to-left" />
        <span>Back</span>
      </a>
      <a
        className={clsx({ 'active-hint-circle': !isViewingSentList })}
        href="#"
        onClick={e => {
          e.preventDefault()
          setViewingSentList(false)
        }}
      >
        <Icon icon="envelope" />
        <span>Have not sent</span>
      </a>
      <a
        className={clsx({ 'active-hint-circle': isViewingSentList })}
        href="#"
        onClick={e => {
          e.preventDefault()
          setViewingSentList(true)
        }}
      >
        <Icon icon="envelope-open" />
        <span>Have sent</span>
      </a>
    </nav>
  )
}

export default PanelNav
