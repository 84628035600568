import clsx from 'clsx'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'

import { Icon, type IconProps, Tooltip } from '@fv/client-components'

import { type MessageParticipant } from '../../hooks/messages/useMessages'

type MessageProps = {
  carrierId?: string
  date: string
  displayName: string
  isApiMessage?: boolean
  isFromShipper?: boolean
  messageText: string
  participants: MessageParticipant[]
}

const Message = ({
  carrierId,
  date,
  displayName,
  isFromShipper,
  messageText,
  participants,
}: MessageProps) => {
  const [isShowingRecipients, setShowingRecipients] = useState(false)
  const timestamp = dayjs(date).format('MMM D @ h:mm a')

  const recipients = useMemo(() => {
    if (!participants) return []

    return participants
      .filter(p => !p.isAuthor && p.carrierId === carrierId)
      .map(r => ({
        ...r,
        ...deliveryStatusProps(r.deliveryStatus),
      }))
  }, [carrierId, participants])

  const statusIcon = useMemo(() => {
    if (recipients.every(r => r.statusText === 'Delivered')) {
      return 'check'
    }

    if (recipients.some(r => r.statusText === 'Failed')) {
      return 'ban'
    }

    return 'paper-plane'
  }, [recipients])

  return (
    <div
      className={clsx('speech-bubble pt-8', {
        'speech-bubble--me': isFromShipper,
        'speech-bubble--them': !isFromShipper,
      })}
    >
      <span>{messageText}</span>
      <div className="time-stamp--speech-bubble text-xs leading-[1.4rem]">
        <Icon
          className="color-dark"
          icon={displayName.match(/system/i) ? 'server' : 'user-circle'}
        />
        <span className="messages-username" title={displayName}>
          {displayName}
        </span>
        <span>/</span>
        <span className="mr-2 whitespace-nowrap">{timestamp}</span>

        {isFromShipper && recipients.length > 0 && (
          <Tooltip label="View email recipients">
            <a
              href="#"
              className="ml-auto"
              onClick={e => {
                e.preventDefault()
                setShowingRecipients(prev => !prev)
              }}
            >
              <Icon icon={statusIcon} />
            </a>
          </Tooltip>
        )}
      </div>

      {isShowingRecipients && (
        <>
          <hr className="mx-0 my-2 mb-2" />
          <ul className="standard-list--lcb-0 standard-list--fcp-0 standard-list--lcp-0">
            {recipients.map(r => (
              <li className="standard-list__item text-xs" key={r.id}>
                <Icon icon={r.statusIcon} className="color-dark" />
                <span>
                  {r.statusText} / {r.email}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

function deliveryStatusProps(status?: string): {
  statusIcon: IconProps['icon']
  statusText: string
} {
  switch (status) {
    case 'delivered':
      return { statusIcon: 'check', statusText: 'Delivered' }
    case 'queued':
    case 'sent':
      return { statusIcon: 'paper-plane', statusText: 'Sent' }
    default:
      return { statusIcon: 'ban', statusText: 'Failed' }
  }
}

export default Message
