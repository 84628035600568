import clsx from 'clsx'
import findLastIndex from 'lodash/findLastIndex'
import { useEffect, useRef } from 'react'

import {
  FvButton,
  FvLinkButton,
  Icon,
  PhoneNumberDisplay,
} from '@fv/client-components'
import { addressFuncs } from '@fv/client-core'

import { ValidatedForm } from '../../components/inputs'
import { PillHeader } from '../../components/PillHeader'
import { usePrettyNumber } from '../../hooks/settings'
import {
  getCustomsBrokerLocationContext,
  isInternational,
  isMexican,
} from '../../utils/shipmentFuncs'
import { defaultFormBroker } from '../customsBroker/brokerFuncs'
import { CustomsBrokerForm } from '../customsBroker/CustomsBrokerForm'
import { useBookingForm } from './hooks'
import { brokerKeyPrefix, composeBrokerKey } from './types'

type CustomsBrokerProps = {
  index: number
}

export const BookingCustomsBroker = ({ index }: CustomsBrokerProps) => {
  const { setFormRef, ...form } = useBookingForm()
  const formRef = useRef<HTMLFormElement>(null)
  const prettyNumber = usePrettyNumber()
  const customsBrokerKey = composeBrokerKey(index)
  const isEditing = form.activeSection === customsBrokerKey
  const section = form.formSections.find(s => s.key === customsBrokerKey)
  const hasSection = !!section
  const { required, location } = getCustomsBrokerLocationContext(form.locations)
  const canAdd =
    index === form.customsBrokers.length - 1 &&
    isMexican(form) &&
    isInternational(form)
  const canDelete = form.customsBrokers.length > 1
  const customsBroker = form.customsBrokers[index]

  useEffect(() => {
    if (!hasSection) return
    setFormRef(customsBrokerKey, formRef)
  }, [hasSection, setFormRef, customsBrokerKey])

  if (!hasSection || !location) return null

  function editCustomsBroker() {
    form.activateSection(customsBrokerKey)
  }

  function addBroker() {
    form.setCustomsBrokers(prev => [...prev, defaultFormBroker])

    const newKey = composeBrokerKey(index + 1)
    const insertIndex = findLastIndex(form.formSections, k =>
      k.key.includes(brokerKeyPrefix),
    )

    form.setFormSections([
      ...form.formSections.slice(0, insertIndex + 1),
      { isValid: false, key: newKey, ref: null },
    ])

    form.activateSection(newKey)
  }

  function removeBroker() {
    form.setCustomsBrokers(prev => {
      const newBrokers = Array.from(prev)
      newBrokers.splice(index, 1)
      return newBrokers
    })

    let brokersIx = -1

    form.setFormSections([
      ...form.formSections
        .filter(s => s.key !== customsBrokerKey)
        .map(s => {
          const isBroker = s.key.includes(brokerKeyPrefix)

          if (isBroker) {
            brokersIx++
          }

          return {
            ...s,
            key: isBroker ? composeBrokerKey(brokersIx) : s.key,
          }
        }),
    ])
  }

  const { email, phone, phoneExt, value } = customsBroker

  return (
    <section className="booking-step-ctn">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section?.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            'beer-container-light -top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
          )}
        >
          {section?.isValid && (
            <FvButton
              theme="round"
              onClick={() => editCustomsBroker()}
              icon="pen-alt"
            />
          )}
          <div>
            <Icon icon="globe-americas" className="color-dark fa-fw" />
            <span>Customs broker</span>
          </div>
          <div className="relative -right-4 ml-auto flex">
            {canAdd && (
              <FvButton icon="plus" theme="round" onClick={() => addBroker()} />
            )}
            {canDelete && (
              <FvButton
                icon="trash"
                theme="round"
                iconClass="color-warning"
                onClick={() => removeBroker()}
              />
            )}
          </div>
        </PillHeader>

        {isEditing && (
          <ValidatedForm onValidSubmit={form.goToNextSection} ref={formRef}>
            <div className="form-row">
              <CustomsBrokerForm
                workflow={'truckload'}
                loadLocation={location}
                autofocus
                nameLabel="Company name"
                setValues={values => {
                  form.setCustomsBrokers(prev =>
                    prev.map((p, i) => {
                      return i === index ? { ...p, ...values } : p
                    }),
                  )
                }}
                required={required}
                values={customsBroker}
              />
            </div>

            <FvButton
              theme="secondary"
              fwd
              icon="arrow-down-to-line"
              type="submit"
            >
              Looks good, proceed
            </FvButton>
          </ValidatedForm>
        )}

        {section?.isValid && (
          <ul className="standard-list standard-list--fcp-0 standard-list--lcb-0">
            <li>
              {addressFuncs.companyAddress(customsBroker, {
                includeLine2: true,
                dependentOnCompany: false,
              })}
              {' / '}
              {phone && <PhoneNumberDisplay val={phone} />}
              {phoneExt && ` ext. ${phoneExt}`}
              {(email || value) && ' / '}
              {email && (
                <FvLinkButton theme="underlined" href={`mailto:${email}`}>
                  {email}
                </FvLinkButton>
              )}
              {email && value && ' / '}
              {value && `$${prettyNumber(value)}`}
            </li>
          </ul>
        )}
      </div>
    </section>
  )
}
