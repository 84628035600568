import clsx from 'clsx'
import upperFirst from 'lodash/upperFirst'
import pluralize from 'pluralize'
import { useCallback } from 'react'

import { FvButton, Icon } from '@fv/client-components'

import { PillHeader } from '../../components/PillHeader'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { usePrettyNumber, useTypeNames } from '../../hooks/settings'
import { CommoditiesForm } from './CommoditiesForm'
import { useBookingForm } from './hooks'
import { commoditiesKey } from './types'

export const BookingCommodities = () => {
  const composeItemSummary = useComposeItemSummary()
  const form = useBookingForm()
  const isEditing = form.activeSection === commoditiesKey
  const section = form.formSections.find(s => s.key === commoditiesKey)

  if (!section) return null

  function editCommodities() {
    form.activateSection(commoditiesKey)
  }

  function setCommodities(
    buildItems: (prev: FormCommodity[]) => FormCommodity[],
  ) {
    const items = buildItems(form.loads[0].items)

    form.setLoads(p =>
      p.map((load, i) => ({
        ...load,
        ...(i === 0 && { items }),
      })),
    )
  }

  return (
    <section className="booking-step-ctn">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            'beer-container-light -top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
          )}
        >
          {section?.isValid && (
            <FvButton
              theme="round"
              onClick={() => editCommodities()}
              icon="pen-alt"
            />
          )}
          <div>
            <Icon icon="box" className="color-dark fa-fw mr-2" />
            Enter each handling unit group that will be delivered for this
            multi-stop shipment.
          </div>
        </PillHeader>

        {isEditing && (
          <CommoditiesForm
            commodities={form.loads[0].items}
            loadId={form.loads[0].loadId}
            locations={form.locations}
            onValidSubmit={form.goToNextSection}
            requiresDeclaredValue={form.settings.requiresDeclaredValue}
            setCommodities={setCommodities}
            setFormRef={form.setFormRef}
          />
        )}

        {section.isValid && (
          <div className="booking-step__summary">
            <ul className="standard-list standard-list--lcb-0 standard-list--lcp-0 standard-list--fcp-0">
              {form.loads[0].items.map(item => (
                <li className="standard-list__item" key={item.id}>
                  <Icon icon="dot-circle" className="fa-fw color-ghosted" />
                  <span>{composeItemSummary(item)}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  )
}

function useComposeItemSummary() {
  const { packageName } = useTypeNames()
  const prettyNumber = usePrettyNumber()

  return useCallback(
    (item: FormCommodity) => {
      const {
        declaredValue,
        declaredValueCurrency,
        description,
        hazardous,
        quantity,
        type,
        weight,
        weightUOM,
      } = item

      let summary = pluralize(packageName(type), Number(quantity), true)
      summary += ` / ${upperFirst(description)}`
      summary += ` / ${prettyNumber(weight)} ${weightUOM}.`

      if (declaredValue) {
        summary += ` / Value: $${prettyNumber(
          declaredValue,
        )} ${declaredValueCurrency.toUpperCase()}`
      }

      summary += ` / Hazmat: ${hazardous}`
      return summary
    },
    [packageName, prettyNumber],
  )
}
