import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvButton } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { useAccountSettings } from '../settings/account-settings/hooks'
import { ExpirationDatePickerPanel } from './ExpirationDatePanel'
import { useUpdateBidExpirationDate } from './mutations'

type Props = { load: FullShipment }
export const BidExpirationEditor = ({ load }: Props) => {
  const { quoting } = useAccountSettings()
  const [showPicker, togglePicker] = useToggle()
  const currentValue = load.bidExpiration?.utc
    ? dayjs.utc(load.bidExpiration.utc).local().toString()
    : ''

  const updateBidExpirationDate = useUpdateBidExpirationDate()
  if (!quoting.showBidExpiration) return null

  const handleSubmit = (value: Date | undefined) => {
    updateBidExpirationDate.mutate(
      {
        loadId: load.loadId,
        localDateTime: !value ? null : dayjs(value).toDate(),
      },
      {
        onSuccess: () => {
          togglePicker()
          toast.success('Bid expiration date has been updated.')
        },
      },
    )
  }
  return (
    <>
      <br />
      <span>
        Bidding expiration:{' '}
        <FvButton theme="underlined" onClick={togglePicker}>
          {currentValue ? (
            <> {dayjs(currentValue).format('ddd, MMM DD / h:mm a')}</>
          ) : (
            <>none</>
          )}
        </FvButton>
      </span>
      <ExpirationDatePickerPanel
        value={currentValue}
        open={showPicker}
        onClose={() => togglePicker()}
        isSaving={updateBidExpirationDate.isLoading}
        onSave={dateTime => {
          handleSubmit(dateTime)
        }}
      />
    </>
  )
}
