import { type ChangeEvent, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  FvButton,
  FvNavLink,
  Icon,
  SelectField,
  useFvNavigate,
} from '@fv/client-components'
import { parseDateRange } from '@fv/client-core'

import { dateRangeBuilders } from '../../components/CustomFilterForm/dateRangeBuilders'
import { dateOptions } from '../../components/CustomFilterForm/filterOptions'
import ExportOptions from '../../components/ExportOptions'
import { usePermissions } from '../auth'
import { useAnalyticsParams } from './hooks'
import { useExportUserReport } from './queries'
import { basePath, lanes, providers, spotQuote, users } from './types'

const getShipmentsQueryString = (qs: string) => {
  const params = new URLSearchParams(qs)
  params.set('isFreightCollect', 'false')
  params.append('status[]', 'confirmed')
  params.append('status[]', 'picked-up')
  params.append('status[]', 'delivered')
  return params.toString()
}

const AnalyticsNav = () => {
  const [isExporting, setExporting] = useState(false)
  const { pathname } = useLocation()
  const { pickupDate, qs, view } = useAnalyticsParams()
  const navigate = useFvNavigate()
  const shipmentsQueryString = getShipmentsQueryString(qs)
  const { canManage } = usePermissions()
  const exportUserReport = useExportUserReport()

  const { name: dateRangeName } = parseDateRange(pickupDate)
  const showExport = view !== spotQuote
  const isUserReport = view === users

  const customOption = {
    text: 'Custom',
    value: 'custom',
  }

  const handleExportClick = () => {
    if (isUserReport) {
      exportUserReport.mutate(shipmentsQueryString)
    } else {
      setExporting(true)
    }
  }

  return (
    <>
      <nav className="flex border-b border-fv-gray mb-3 pb-3 border-dotted">
        <SelectField
          className="form-control form-control--select col-auto"
          name="pickupDate"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            let value = e.target.value

            if (value === 'custom' && pickupDate) {
              value = dateRangeBuilders[pickupDate]()
            }

            const params = new URLSearchParams(qs)
            params.set('pickupDate', value)
            navigate(`${pathname}?${params.toString()}`)
          }}
          options={dateOptions.concat(customOption)}
          value={dateRangeName.toString()}
        />

        <div className="divided-content divided-content--start ml-4">
          <FvNavLink
            className={({ isActive }) => (isActive ? 'active-hint-circle' : '')}
            to={`${basePath}/${providers}?${qs}`}
          >
            <Icon icon="truck-moving" />
            <span className="b1400:hidden">Service providers</span>
            <span className="b1400:inline-block hidden">Providers</span>
          </FvNavLink>

          <FvNavLink
            className={({ isActive }) => (isActive ? 'active-hint-circle' : '')}
            to={`${basePath}/${spotQuote}?${qs}`}
          >
            <Icon icon="comment-alt-dollar" transform="down-1" />
            <span>Spot quote</span>
          </FvNavLink>

          <FvNavLink
            className={({ isActive }) => (isActive ? 'active-hint-circle' : '')}
            to={`${basePath}/${lanes}?${qs}`}
          >
            <Icon icon="map-signs" />
            <span>Lanes</span>
          </FvNavLink>
          {canManage && (
            <FvNavLink
              className={({ isActive }) =>
                isActive ? 'active-hint-circle' : ''
              }
              to={`${basePath}/${users}?${qs}`}
            >
              <Icon icon="users" />
              <span>Users</span>
            </FvNavLink>
          )}
        </div>
        <div className="border-fv-blue-300 flex-1 self-center border-t border-dotted" />
        <div className="divided-content divided-content--start ml-auto pl-4">
          {!isUserReport && (
            <FvNavLink to={`/active?${shipmentsQueryString}`}>
              <Icon icon="list" />
              <span>View related shipments</span>
            </FvNavLink>
          )}

          {showExport && (
            <FvButton icon="file-export" onClick={handleExportClick}>
              <span className="b1400:hidden">Export list</span>
            </FvButton>
          )}
        </div>
      </nav>

      <ExportOptions
        closePanel={() => setExporting(false)}
        isOpen={isExporting}
        queryString={shipmentsQueryString}
      />
    </>
  )
}

export default AnalyticsNav
