import clsx from 'clsx'
import dayjs from 'dayjs'

import { DelimitedContent, Icon, Slash, Tooltip } from '@fv/client-components'
import { displayLocShipType, getLocAccessorials } from '@fv/client-core'
import {
  type LegacyShipType,
  type ShipmentLocation,
  type SlimTrackDetail,
  type UIQuote,
  type Workflow,
} from '@fv/client-types'

import { quoteFuncs } from '../../features/quote/quoteFuncs'
import { useTypeNames } from '../../hooks/settings'
import { getApplicableAccessorials } from '../../utils/locationFuncs'

type StopListLocation = Pick<
  ShipmentLocation,
  | 'accessorials'
  | 'company'
  | 'refNums'
  | 'sequence'
  | 'stopDateType'
  | 'stopType'
  | 'type'
> & {
  city?: string
  postalCode?: string
  shipType?: LegacyShipType | ''
  state?: string
  stopDate?: Date | string | null
}

type ExpandedDetailsProps = Pick<
  ShipmentLocation,
  'accessorials' | 'refNums'
> & {
  shipType?: LegacyShipType | ''
}

const ExpandedDetails = ({
  accessorials,
  shipType,
  refNums,
}: ExpandedDetailsProps) => {
  const typeNames = useTypeNames()
  const shipTypeDisplay = displayLocShipType({ shipType, accessorials })
  const accessorialList = getLocAccessorials({ accessorials }, typeNames)
    ?.map(a =>
      a.key === 'schedule' && a.appointmentTime
        ? `${a.name} (${a.appointmentTime})`
        : a.name,
    )
    ?.join(' / ')

  return (
    <li className="standard-list__item flex flex-nowrap items-start">
      <Icon
        className="fa-fw color-ghosted flex-none"
        icon="dot-circle"
        transform="down-1.5"
      />
      <p className="mb-0">
        <DelimitedContent delimiter={<Slash />}>
          {!!refNums?.length && `Ref# ${refNums.map(n => n.value).join(' / ')}`}
          {shipTypeDisplay}
          {!!accessorialList?.length && accessorialList}
        </DelimitedContent>
      </p>
    </li>
  )
}

type StopProps = {
  address: string
  date: string
  isDestination?: boolean
  isOrigin?: boolean
  label: string
}

const Stop = ({ address, date, label, isOrigin, isDestination }: StopProps) => (
  <div className="divided-content divided-content--condensed divided-content--start shipment-card__stop">
    <span className="shipment-card__stop-type">
      <Icon
        icon="calendar"
        className={clsx('fa-fw', {
          'text-[#ff9a59]': isOrigin,
          'text-[#99d87a]': isDestination,
          'text-[#d4d4a3]': !isOrigin && !isDestination,
        })}
      />
      <span>{label}</span>
    </span>
    <span className="shipment-card__stop-date">{date}</span>
    <p className="shipment-card__location mb-0 flex flex-nowrap items-start !overflow-hidden">
      <Icon icon="building" className="color-dark fa-fw" transform="down-2.2" />
      <Tooltip label={address} onlyOnOverflow>
        <span className="limit-characters">{address}</span>
      </Tooltip>
    </p>
  </div>
)

type StopDetailsProps = {
  isDestination: boolean
  isOrigin: boolean
  location: StopListLocation
  pickupDate?: string
  quote?: UIQuote
  tracking?: SlimTrackDetail
  workflow: Workflow
}

const StopDetails = ({
  isDestination,
  isOrigin,
  location,
  pickupDate,
  quote,
  tracking,
}: StopDetailsProps) => {
  const {
    city,
    company,
    postalCode,
    sequence,
    state,
    stopDate,
    stopDateType,
    stopType,
  } = location || {}

  const {
    currentStatus,
    deliveryDateActual,
    deliveryDateEstimate,
    lastUpdatedDate,
  } = tracking || {}

  const address = `${city} ${state} ${postalCode}`
  let fullAddress = company ? `${company}, ${address}` : address

  if (!postalCode) {
    if (isOrigin) fullAddress = 'Origin'
    else if (isDestination) fullAddress = 'Destination'
    else fullAddress = `Stop ${sequence}`
  }

  if (stopType === 'pickup') {
    const label = `Pick up${stopDateType ? ` ${stopDateType}` : ''}`
    const date = formatDate(stopDate, true)

    return (
      <Stop
        address={fullAddress}
        date={date || 'NA'}
        label={label}
        isOrigin={isOrigin}
      />
    )
  }

  if (stopType === 'delivery') {
    const estimate =
      deliveryDateEstimate ||
      stopDate ||
      quoteFuncs.getEstimatedDeliveryDate(pickupDate, quote)?.format()

    if (
      isDestination &&
      currentStatus !== 'delivered' &&
      !estimate &&
      !stopDate
    ) {
      return (
        <Stop
          address={fullAddress}
          date="NA"
          label="Estimated"
          isDestination={isDestination}
        />
      )
    }

    const type = stopDateType ? ` ${stopDateType}` : ''
    let label = isDestination ? `Deliver${type}` : `Drop off${type}`
    let date =
      isDestination && estimate
        ? formatDate(estimate)
        : formatDate(stopDate, true)

    if (isDestination && estimate) label = 'Estimated'
    if (isDestination && currentStatus === 'delivered') {
      label = 'Delivered'
      date = formatDate(deliveryDateActual ?? lastUpdatedDate)
    }

    return (
      <Stop
        address={fullAddress}
        date={date || 'NA'}
        label={label}
        isDestination={isDestination}
      />
    )
  }

  const date = formatDate(stopDate, true)
  const label = `PU/DO${stopDateType ? ` ${stopDateType}` : ''}`

  return <Stop address={fullAddress} date={date || 'NA'} label={label} />
}

interface ExpandableStopProps extends StopDetailsProps {
  isExpanded?: boolean
}

const ExpandableStop = ({
  isDestination,
  isExpanded,
  isOrigin,
  location,
  pickupDate,
  quote,
  tracking,
  workflow,
}: ExpandableStopProps) => {
  const { refNums } = location
  const accessorials = getApplicableAccessorials(location, workflow)
  const isExpandable = !!accessorials?.length || !!refNums?.length

  return (
    <>
      <li
        className={clsx('standard-list__item flex flex-nowrap items-start', {
          'standard-list__item--dashed': isExpandable && isExpanded,
        })}
      >
        <span className="flex-1 !overflow-hidden leading-[1.4rem]">
          <StopDetails
            isDestination={isDestination}
            isOrigin={isOrigin}
            location={location}
            tracking={tracking}
            workflow={workflow}
            pickupDate={pickupDate}
            quote={quote}
          />
        </span>
      </li>

      {isExpandable && isExpanded && (
        <ExpandedDetails
          accessorials={accessorials}
          shipType={location.shipType}
          refNums={refNums}
        />
      )}
    </>
  )
}

type StopListProps = {
  isExpanded?: boolean
  locations: StopListLocation[]
  tracking?: SlimTrackDetail
  workflow: Workflow
  quote?: UIQuote
}

export const StopList = ({
  isExpanded,
  locations,
  quote,
  tracking,
  workflow,
}: StopListProps) => {
  const pickupDate =
    tracking?.pickupDateActual ||
    locations.find(loc => loc.type === 'origin')?.stopDate?.toString()

  return (
    <>
      {locations.map(x => (
        <ExpandableStop
          isDestination={x.sequence === locations.length - 1}
          isExpanded={isExpanded}
          isOrigin={x.sequence === 0}
          key={x.sequence}
          location={x}
          tracking={tracking}
          workflow={workflow}
          pickupDate={pickupDate}
          quote={quote}
        />
      ))}
    </>
  )
}
function formatDate(date?: Date | string | null, utc?: boolean) {
  if (!date) return ''
  const day = utc ? dayjs.utc(date) : dayjs(date)
  return day.format('ddd MMM D')
}
