import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import {
  DelimitedContent,
  FvButton,
  Icon,
  SliderPanel,
  Tooltip,
} from '@fv/client-components'
import { showApiError } from '@fv/client-core'
import { type UserPermissionType } from '@fv/client-types'

import { ActionListItem } from '../../components/ActionList'
import {
  List,
  ListHeaderActions,
  ListItem,
  ListItemActions,
} from '../../components/List'
import { Loading } from '../../components/Loading'
import { HasPermission } from '../auth'
import { SettingsPageLayout } from '../settings/SettingsPageLayout'
import { SettingsSection } from '../settings/SettingsSection'
import { AccountUserForm } from './AccountUserForm'
import { useRemoveUser, useResendInvite } from './mutations'
import { useAccountUsers } from './queries'
import { type AccountUserResponseDto } from './types'

const nameDisplay = (u: AccountUserResponseDto) =>
  u.firstName ? `${u.firstName} ${u.lastName}` : u.username

export const AccountUsersPage = () => {
  const { data: users, isLoading } = useAccountUsers()
  const removeUser = useRemoveUser()
  const resendInvite = useResendInvite()
  const [showAdd, toggleAdd, setShowAdd] = useToggle(false)
  const [editing, setEditing] = useState<AccountUserResponseDto>()

  const sortedUsers = orderBy(users ?? [], [
    u => !u.owner,
    u => u.firstName || u.username,
    u => u.lastName,
  ])
  const closePanel = () => {
    setEditing(undefined)
    setShowAdd(false)
  }
  const handleRemoveClick = (u: AccountUserResponseDto) => async () => {
    if (!window.confirm('Are you sure you want to remove this user?')) {
      return
    }
    try {
      await removeUser.mutateAsync({ _id: u._id })
    } catch (e) {
      showApiError('Failed to remove user', e)
    }
  }
  const handleResendInviteClick = (u: AccountUserResponseDto) => async () => {
    try {
      await resendInvite.mutateAsync(u)
      toast.success('Resent invite')
    } catch (e) {
      showApiError('Failed to resend invite', e)
    }
  }
  if (isLoading) {
    return (
      <div className="m-8">
        <Loading />
      </div>
    )
  }
  return (
    <SettingsPageLayout>
      <SettingsSection title="Users">
        <ListHeaderActions>
          <FvButton icon="plus" onClick={toggleAdd}>
            Add user
          </FvButton>
        </ListHeaderActions>
        <List className="mt-2">
          {sortedUsers.map(u => (
            <ListItem
              className="flex-wrap py-4"
              key={u.username}
              actions={
                !u.owner && (
                  <ListItemActions>
                    {!u.registered && (
                      <HasPermission name="manage">
                        <ActionListItem
                          tooltip="Resend invite"
                          icon="envelope"
                          onClick={handleResendInviteClick(u)}
                        />
                      </HasPermission>
                    )}
                    <ActionListItem
                      icon="pen-alt"
                      onClick={() => setEditing(u)}
                    />
                    <ActionListItem
                      icon="trash"
                      iconClass="text-fv-orange"
                      onClick={handleRemoveClick(u)}
                    />
                  </ListItemActions>
                )
              }
            >
              <div className="mb-2 w-full border-b border-dotted pb-1">
                <Icon icon="user" className="color-dark fa-fw" />
                <span>
                  {nameDisplay(u)}
                  {u.owner && <span className="text-sm">&nbsp;(owner)</span>}
                </span>
              </div>

              {!u.registered && (
                <div>
                  <Tooltip label="Invitation sent">
                    <Icon icon="clock" className="color-dark fa-fw" />
                  </Tooltip>
                </div>
              )}

              <div className="text-fv-gray-600 flex items-center gap-x-3">
                <DelimitedContent
                  className="text-gray-300"
                  delimiter={<div className="h-4 w-px bg-gray-300" />}
                >
                  <PermissionDisplay
                    value="manage"
                    permissions={u.permissions}
                  />
                  <PermissionDisplay value="book" permissions={u.permissions} />
                  <PermissionDisplay
                    value="quote"
                    permissions={u.permissions}
                  />
                  <PermissionDisplay
                    value="audit"
                    permissions={u.permissions}
                  />
                  {!!(u.rate_modifier ?? 0) && (
                    <div>{u.rate_modifier ?? 0}% markup</div>
                  )}
                </DelimitedContent>
              </div>
            </ListItem>
          ))}
        </List>
      </SettingsSection>
      <SliderPanel isOpen={showAdd || !!editing} closePanel={closePanel}>
        <AccountUserForm
          onSaved={closePanel}
          onCancel={closePanel}
          existingUser={editing}
        />
      </SliderPanel>
    </SettingsPageLayout>
  )
}

type PdProps = {
  value: UserPermissionType
  permissions: UserPermissionType[]
}
const PermissionDisplay = ({ value, permissions }: PdProps) => {
  const isEnabled = permissions.includes(value)
  return (
    <div className=" flex items-center">
      <Icon
        icon={isEnabled ? 'check-circle' : 'ban'}
        className={clsx('fa-fw text-green-400', {
          '!text-gray-300': !isEnabled,
        })}
      />
      <span>{[permissionNames[value]]}</span>
    </div>
  )
}

const permissionNames: Record<UserPermissionType, string> = {
  audit: 'Audit',
  book: 'Book',
  manage: 'Manage',
  quote: 'Quote',
}
