import clsx from 'clsx'

import { Icon, type IconProps } from '@fv/client-components'

type QuoteListFilterProps = {
  active: boolean
  icon: IconProps['icon']
  label: string
  onClick: () => void
}

const QuoteListFilter = ({
  active,
  icon,
  label,
  onClick,
}: QuoteListFilterProps) => {
  return (
    <a
      className={clsx(active && 'active-hint-circle')}
      href="#"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    >
      <Icon icon={icon} />
      <span>{label}</span>
    </a>
  )
}

export default QuoteListFilter
