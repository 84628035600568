import clsx from 'clsx'

import { FvLinkButton } from '@fv/client-components'
import { type ClientAddressLookupResult } from '@fv/client-types'

import { ActionList, ActionListItem } from '../../components/ActionList'
import { ListItem } from '../../components/List'
import { type SetAddress } from './types'
import { useAddressBook } from './useAddressBook'
import { useDeleteAddress } from './useDeleteAddress'

type Props = {
  address: ClientAddressLookupResult
  selectAddress?: SetAddress
  canRemove?: boolean
  listCanRemove?: boolean
}

export const AddressBookEntry = ({
  address,
  selectAddress,
  canRemove,
  listCanRemove,
}: Props) => {
  const canEdit = useAddressBook(s => !s.config.postalCodeFilter)
  const edit = useAddressBook(s => s.edit)
  const remove = useDeleteAddress()

  const handleDeleteClick = async () => {
    if (
      !address._id ||
      !window.confirm(
        'Are you sure you want to remove this address book entry?',
      )
    ) {
      return
    }
    return await remove.mutateAsync({ id: address._id })
  }

  const {
    adminArea3,
    address: street,
    city,
    company,
    isAccountLocation,
    postalCode,
    state,
  } = address

  return (
    <ListItem
      className={`hover:!border-fv-blue hover:bg-white hover-list-item transition-colors duration-100 ${isAccountLocation ? 'hover:!border-fv-orange hover-list-item--priority' : 'hover:!border-fv-blue'}`}
      custom
    >
      <FvLinkButton
        className="py-4 flex-1 truncate"
        fw
        icon={isAccountLocation ? 'industry-alt' : 'building'}
        iconClass={clsx({ 'text-fv-orange': isAccountLocation })}
        onClick={() =>
          selectAddress ? selectAddress(address) : edit(address._id)
        }
      >
        {company} / {street} / {adminArea3 || ''} {city} {state} {postalCode}
      </FvLinkButton>
      <ActionList className="ml-auto">
        {canEdit && address._id && (
          <ActionListItem
            icon="pen-alt"
            onClick={() => {
              if (!address._id) return
              edit(address._id)
            }}
          />
        )}
        {listCanRemove && address._id && (
          <div>
            {canRemove && (
              <ActionListItem
                icon="trash"
                iconClass="color-warning"
                onClick={handleDeleteClick}
              />
            )}
          </div>
        )}
      </ActionList>
    </ListItem>
  )
}
