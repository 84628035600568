import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode, useState } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import {
  FedEx,
  FvButton,
  FvLinkButton,
  ValidatedForm,
} from '@fv/client-components'
import { useFormModel } from '@fv/client-core'

import InfoBox from '../../../components/InfoBox'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { Loading } from '../../../components/Loading'
// import { supportMessage } from '../../../constants'
import { useAccountLocations } from '../../addresses/useAddress'
import { AddressForm } from '../components/AddressForm'
import {
  useTestAccountCarrierLocation,
  useUpdateAccountCarrierLocation,
} from '../mutations'
import { useAccountCarriers } from '../queries'
import { type BaseProvisionLocationFormProps } from '../types'
import { buildDetailsFromModel, buildModelFromDetails } from './hooks'
import { type BillToWithAccountNumber } from './types'

type SectionProps = PropsWithChildren<{
  title: ReactNode
  collapsible?: boolean
  collapsed?: boolean
  onCollapseToggle?: () => void
}>

const Section = ({
  title,
  children,
  collapsed,
  collapsible,
  onCollapseToggle,
}: SectionProps) => {
  return (
    <div>
      <div className="flex justify-between mb-4 border-b ">
        <h5 className="text-lg">{title}</h5>
        {collapsible && (
          <FvButton
            onClick={onCollapseToggle}
            icon="chevron-down"
            className={clsx('transition-transform', {
              'rotate-180': collapsed,
            })}
          />
        )}
      </div>
      {!collapsed && <>{children}</>}
    </div>
  )
}

const defaultModel: BillToWithAccountNumber = {
  accountNumber: '',
  address: '',
  city: '',
  country: 'us',
  postalCode: '',
  state: '',
}

export const FedexLocationDetails = (props: BaseProvisionLocationFormProps) => {
  const [directCollapsed, toggleDirectCollapsed] = useToggle(true)
  const mutation = useUpdateAccountCarrierLocation()
  const testProvisioning = useTestAccountCarrierLocation()
  const [errors, setErrors] = useState<string[]>([])
  const { carrierId, locationId, onSuccess, onCancel } = props
  const { data: carriers } = useAccountCarriers()
  const accountCarrierData = carriers?.find(
    c => c.carrierId === props.carrierId,
  )
  const { data: locations } = useAccountLocations()
  const loc = locations?.find(l => l._id === locationId)
  const carrierLoc = accountCarrierData?.locations?.find(
    l => l.locationId === locationId,
  )

  const [freightModel, setFreightModel] = useState<BillToWithAccountNumber>({
    ...defaultModel,
    ...(carrierLoc &&
      buildModelFromDetails('freight-account', carrierLoc.details ?? [])),
  })
  const { register: freightRegister } = useFormModel<BillToWithAccountNumber>({
    onChange: setFreightModel,
    key: 'freight',
    value: freightModel,
  })
  const [directModel, setDirectModel] = useState<BillToWithAccountNumber>({
    ...defaultModel,
    ...(carrierLoc &&
      buildModelFromDetails('direct-account', carrierLoc.details ?? [])),
  })
  const { register: directRegister } = useFormModel<BillToWithAccountNumber>({
    onChange: setDirectModel,
    key: 'direct',
    value: directModel,
  })

  const handleSubmit = async () => {
    setErrors([])
    const details = [
      ...buildDetailsFromModel('freight-account', freightModel),
      ...buildDetailsFromModel('direct-account', directModel),
    ]
    await mutation.mutateAsync({
      carrierId,
      locationId,
      details,
    })
    try {
      await testProvisioning.mutateAsync({
        carrierId,
        locationId,
      })
    } catch (e) {
      // Swallow this for now
    }
    // if (!result.success) {
    //   setErrors([
    //     `We were unable to get any rates with those details, ${supportMessage}`,
    //   ])
    // } else {
    //   const directError =
    //     details.some(d => d.type === 'direct-account-number' && d.value) &&
    //     !result.quotes.some(q => q.serviceId.includes('direct'))
    //   const freightError =
    //     details.some(d => d.type === 'freight-account-number' && d.value) &&
    //     !result.quotes.some(q => q.serviceId.includes('freight'))

    //   if (directError || freightError) {
    //     setErrors(p => [
    //       ...p,
    //       ...(freightError
    //         ? [
    //             `Your FedEx Freight account details failed to return rates, ${supportMessage}`,
    //           ]
    //         : []),
    //       ...(directError
    //         ? [
    //             `Your FedEx Freight Direct account details failed to return rates, ${supportMessage}`,
    //           ]
    //         : []),
    //     ])
    //   } else {
    toast.success('Successfully added details to location')
    onSuccess()
    // }
    // }
  }

  if ((!loc && locationId !== 'third-party') || !accountCarrierData) {
    return <Loading />
  }

  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <p>
        Please enter your <FedEx />
        account details for{' '}
        <span className="underline">{loc?.street || 'Third party'}</span>. If
        you would like <FedEx direct /> rates then the Freight Direct form is
        required.
      </p>
      <div className="flex flex-col gap-y-8">
        <Section
          title={
            <>
              <FedEx freight /> Details
            </>
          }
        >
          <div className="flex flex-col">
            <InputGroup
              inputType="text"
              label={
                <>
                  <FedEx freight /> account number
                </>
              }
              inputProps={{
                ...freightRegister('accountNumber'),
              }}
            />
            <AddressForm register={freightRegister} />
          </div>
        </Section>
        <Section
          collapsible
          collapsed={directCollapsed}
          onCollapseToggle={toggleDirectCollapsed}
          title={
            <>
              <FedEx direct /> Details
              <FvLinkButton
                theme="callout"
                className="text-xs ml-4"
                target="_blank"
                href="https://www.fedex.com/en-us/shipping/freight-services/ltl/freight-direct.html"
              >
                Read more
              </FvLinkButton>
            </>
          }
        >
          <div className="flex flex-col">
            <InputGroup
              inputType="text"
              label={
                <>
                  <FedEx direct /> account number
                </>
              }
              inputProps={{
                ...directRegister('accountNumber'),
              }}
            />
            <AddressForm register={directRegister} />
          </div>
        </Section>
      </div>
      <hr />
      {!!errors.length && (
        <InfoBox icon="exclamation-triangle">
          {errors.map((e, ix) => (
            <p key={ix}>{e}</p>
          ))}
        </InfoBox>
      )}
      <div className="flex justify-end">
        <FvButton theme="plain" onClick={onCancel}>
          Cancel
        </FvButton>
        <FvButton
          theme="primary"
          type="submit"
          loading={mutation.isLoading || testProvisioning.isLoading}
        >
          Save
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
