import { type ParsedQuery } from 'query-string'
import { lazy, Suspense } from 'react'

import InfoBox from '../../components/InfoBox'
import TopNav from '../../components/TopNav'
import { AnalyticsFiltersProvider } from '../shipment-filters/AnalyticsFiltersProvider'
import { CompactFilterBar } from '../shipment-filters/CompactFilterBar'
import { ShipmentFilterPanel } from '../shipment-filters/FilterPanel'
import { useHandleFilterChange } from '../shipment-filters/hooks'
import AnalyticsNav from './AnalyticsNav'
import { useAnalyticsParams } from './hooks'
import { lanes, providers, spotQuote, users } from './types'

const LaneAnalytics = lazy(() => import('./LaneAnalytics'))
const ProviderAnalytics = lazy(() => import('./ProviderAnalytics'))
const SpotQuoteAnalytics = lazy(() => import('./SpotQuoteAnalytics'))
const UserReportAnalytics = lazy(() => import('./UserAnalytics'))

export const AnalyticsPage = () => {
  const { view } = useAnalyticsParams()
  const { searchParams, handle: handleFilterChange } = useHandleFilterChange()

  const onFilterChange = (query: ParsedQuery) => {
    handleFilterChange({
      ...query,
      pickupDate: searchParams.get('pickupDate'),
    })
  }

  return (
    <div className="grid-analytics grid bg-fv-gray-100 h-screen grid-cols-[19rem_1fr] grid-rows-[auto_1fr_auto] b1300:grid-cols-1">
      <AnalyticsFiltersProvider>
        <TopNav />
        <ShipmentFilterPanel onChange={onFilterChange} />

        <div className="analytics-cards-ctn">
          <AnalyticsNav />
          <div className="b1300:block border-t-fv-gray mt-4 hidden border-t border-dashed pt-4">
            <CompactFilterBar onChange={onFilterChange} />
          </div>

          <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
            {view === lanes && <LaneAnalytics />}
            {view === providers && <ProviderAnalytics />}
            {view === spotQuote && <SpotQuoteAnalytics />}
            {view === users && <UserReportAnalytics />}
          </Suspense>
        </div>
      </AnalyticsFiltersProvider>
    </div>
  )
}
