import { useState } from 'react'

import { Icon } from '@fv/client-components'

import { HasFeature } from '../../auth'
import { SettingsSection } from '../SettingsSection'
import FavoriteEquipmentPanel from './FavoriteEquipmentPanel'
import { useEquipmentTypeEnums, useFavoriteEquipmentTypes } from './hooks'

const TruckloadPreferences = () => {
  const [isEditingEquipment, setEditingEquipment] = useState(false)
  const equipmentTypeEnums = useEquipmentTypeEnums('truckload')
  const favoriteEquipment = useFavoriteEquipmentTypes()

  return (
    <HasFeature name="truckLoad">
      <SettingsSection title="Truckload Quoting">
        <ul className="standard-list standard-list--extra-vert-pad standard-list--fcp-0 standard-list--lcb-0 standard-list--lcp-0">
          <li className="standard-list__item divided-content divided-content--start leading-[1.6rem]">
            <span>Use the following equipment types as my favorites</span>
            <a
              href="#"
              className="whitespace-nowrap"
              onClick={e => {
                e.preventDefault()
                setEditingEquipment(true)
              }}
            >
              <Icon icon="pen-alt" />
              <span>Edit</span>
            </a>
          </li>
          <li className="standard-list__item settings-favorite-equipment">
            {favoriteEquipment.map(key => {
              const equipment = equipmentTypeEnums.find(e => e.key === key)

              return (
                <div className="flex items-center" key={key}>
                  <div className="limit-characters">
                    <Icon icon="dot-circle" className="color-dark" />
                    <span>{equipment?.name ?? key}</span>
                  </div>
                  <div className="flex-pinline flex-pinline--subtle flex-pinline--tall" />
                </div>
              )
            })}
          </li>
        </ul>
      </SettingsSection>

      <FavoriteEquipmentPanel
        initialFavorites={favoriteEquipment}
        isOpen={isEditingEquipment}
        setOpen={setEditingEquipment}
      />
    </HasFeature>
  )
}

export default TruckloadPreferences
