import { DateRange, DateRangeNames } from '@fv/client-types'

export type ParsedDateRange = {
  name: DateRange | 'custom'
  isCustom: boolean
  start?: Date
  end?: Date
}

export const parseDateRange = (
  value: string | null | undefined,
  defaultRange: DateRange = 'last-7-days',
): ParsedDateRange => {
  if (!value) {
    return {
      name: defaultRange,
      isCustom: false,
      start: undefined,
      end: undefined,
    }
  }

  const isCustom = !DateRangeNames.includes(value as DateRange)
  const dateRangeQuery = value?.split('-') ?? []
  const hasRangeQuery = dateRangeQuery.length === 2
  const [firstValue, secondValue] = dateRangeQuery

  const start = hasRangeQuery ? new Date(Number(firstValue)) : undefined
  const end =
    hasRangeQuery && secondValue ? new Date(Number(secondValue)) : undefined

  return {
    name: isCustom ? 'custom' : (value as DateRange),
    isCustom,
    start,
    end,
  }
}
