import { useState } from 'react'

import { type FullShipment, type ListShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import InactiveCarrierListItem from '../../components/list/InactiveCarrierListItem'
import { useQuoteRequestResponses } from '../../hooks/shipments'
import { MiniQuoteListItem } from './MiniQuoteListItem'

export type InactiveFilterType = 'pending' | 'declined'

type MiniQuoteListListProps = {
  inactiveFilter?: InactiveFilterType
  isActiveCarriers: boolean
  load: FullShipment | ListShipment
  readOnly?: boolean
  canViewMessages?: boolean
}

export const MiniQuoteList = ({
  inactiveFilter = 'pending',
  isActiveCarriers,
  load,
  readOnly,
  canViewMessages,
}: MiniQuoteListListProps) => {
  const { selectedQuote } = load
  const [activeRowId, setActiveRowId] = useState<string | undefined>()
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(
    selectedQuote?._id,
  )

  const awardedQuoteId = selectedQuote?._id

  const responses = useQuoteRequestResponses({
    filter: 'price',
    load,
  })

  if (isActiveCarriers) {
    return (
      <ul className="standard-list mb-4">
        {responses.active.map(response => (
          <MiniQuoteListItem
            awardedQuoteId={awardedQuoteId}
            isActive={activeRowId === response.id}
            isChecked={selectedRowId === response.id}
            key={response.id}
            canViewMessages={canViewMessages}
            load={load}
            quote={response}
            readOnly={readOnly}
            setActiveQuoteId={setActiveRowId}
            setSelectedQuoteId={setSelectedRowId}
          />
        ))}

        {!responses.active.length && (
          <InfoBox>Nobody has responded yet.</InfoBox>
        )}
      </ul>
    )
  }

  return (
    <ul className="standard-list mb-8">
      {responses[inactiveFilter].map(response => (
        <InactiveCarrierListItem
          carrier={response}
          hasNewActivity={false} // TODO
          isActive={activeRowId === response.id}
          key={response.id}
          load={load}
          setActiveQuoteId={setActiveRowId}
        />
      ))}

      {!responses[inactiveFilter].length && (
        <InfoBox>
          {inactiveFilter === 'pending'
            ? 'No pending responses.'
            : 'Nobody has declined.'}
        </InfoBox>
      )}
    </ul>
  )
}
