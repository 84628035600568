import clsx from 'clsx'
import { useState } from 'react'

import { FvLinkButton } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import MessageField from '../messaging/MessageField'
import MessageList from '../messaging/MessageList'
import { NoteList } from './NoteList'
import { ShipmentTags } from './ShipmentTags'

type PanelName = 'messages' | 'notes' | 'tags'
type MessagesNotesTagsPanelProps = {
  closePanel?: () => void
  load: FullShipment
  className?: string
  navClass?: string
  contentClass?: string
}

export const MessagesNotesTagsControls = ({
  closePanel,
  load,
  className,
  navClass,
  contentClass,
}: MessagesNotesTagsPanelProps) => {
  const [activePanel, setActivePanel] = useState<PanelName>('messages')
  const { loadId, selectedQuote, status } = load
  const isCanceled = status === 'canceled'

  return (
    <div className={className}>
      <nav
        className={clsx('divided-content', 'divided-content--start', navClass)}
      >
        {closePanel && (
          <FvLinkButton icon="arrow-to-left" onClick={closePanel}>
            Back
          </FvLinkButton>
        )}
        <FvLinkButton
          className={clsx({
            'active-hint-circle': activePanel === 'messages',
          })}
          onClick={() => setActivePanel('messages')}
          icon="comment-alt"
          transform="down-1"
        >
          Messages
        </FvLinkButton>
        <FvLinkButton
          className={clsx({
            'active-hint-circle': activePanel === 'notes',
          })}
          onClick={() => setActivePanel('notes')}
          icon="sticky-note"
        >
          Notes
        </FvLinkButton>
        <FvLinkButton
          className={clsx({
            'active-hint-circle': activePanel === 'tags',
          })}
          onClick={() => setActivePanel('tags')}
          icon="tags"
        >
          Tags
        </FvLinkButton>
      </nav>
      <div className={clsx('overflow-auto', contentClass)}>
        {activePanel === 'messages' && (
          <div>
            {!isCanceled && (
              <MessageField
                carrierId={selectedQuote?.carrierId}
                loadId={loadId}
              />
            )}

            <MessageList
              carrierId={selectedQuote?.carrierId}
              isApiMessages={selectedQuote?.method === 'api'}
              loadId={loadId}
            />
          </div>
        )}

        {activePanel === 'notes' && <NoteList loadId={loadId} />}

        {activePanel === 'tags' && <ShipmentTags loadId={loadId} />}
      </div>
    </div>
  )
}
