import { type PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  label: string
  name: string
}>

export const AccountSettingRow = ({ label, name, children }: Props) => {
  return (
    <li className="standard-list__item">
      <div className="flex items-center">
        <label htmlFor={name} className="!leading-[1.6rem]">
          {label}
        </label>
        <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
        <div>{children}</div>
      </div>
    </li>
  )
}
