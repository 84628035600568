import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { freightviewUri, routes } from '../../constants'
import { usePermissions } from '../../features/auth'
import { useShipmentLabels } from '../../features/labels/hooks'
import { useArchiveLoads } from '../../hooks/shipments'
import { useBolLinkState } from '../../hooks/shipments/useBolLinkState'
import { composeEditShipmentUrl } from '../../hooks/shipments/useEditBookingPage'
import { useRepeatShipment } from '../../hooks/shipments/useRepeatShipment'
import { canConfirmShipment } from '../../utils/shipmentFuncs'
import { legacyShipmentLink } from '../../utils/stringTransforms'
import UploadDocumentField from '../inputs/UploadDocumentField'
import { type PanelName } from './LoadDetailPanel'

type Props = {
  setActivePanel: Dispatch<SetStateAction<PanelName>>
  shipment: FullShipment
  showRatesPanel: () => void
}

const ShipmentActions = ({
  setActivePanel,
  shipment,
  showRatesPanel,
}: Props) => {
  const [showUploadBOL, setShowUploadBOL] = useState(false)
  const { canBook } = usePermissions()
  const { isLegacy, status } = shipment ?? {}
  const { archive, canArchive, isArchiving } = useArchiveLoads(status)
  const closeUploadField = useCallback(() => setShowUploadBOL(false), [])
  const labels = useShipmentLabels(shipment)
  const repeat = useRepeatShipment(shipment)

  const {
    carrierBolUrl,
    fvBolUrl,
    hideFvBolFromSpotQuote,
    canFinishBol,
    isProcessing: isBolProcessing,
  } = useBolLinkState(shipment)

  useEffect(() => {
    setShowUploadBOL(false)
  }, [shipment?.loadId])

  if (!shipment) return null

  const { isArchived, loadId, workflow, pickup } = shipment
  const isProcessingPickup = pickup?.status === 'requesting'
  const isAwarded = status === 'awarded'
  const isCanceled = status === 'canceled'
  const isConfirmed = status === 'confirmed'
  const isBooked = isAwarded || isConfirmed
  const canRetender = isLegacy
    ? isAwarded
    : isBooked && pickup?.method !== 'api'

  const finishBolLink = composeEditShipmentUrl(shipment, 'listing', 'finishBOL')
  const canSelectCarrier = canRetender && canBook && !!showRatesPanel
  const canUploadBol = !isCanceled && !isLegacy && canBook
  const canUpdateTracking =
    canBook && (isConfirmed || status === 'picked-up' || status === 'delivered')

  return (
    <ul className="standard-list">
      {canConfirmShipment(shipment) && canBook && (
        <li className="standard-list__item">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              !isProcessingPickup && setActivePanel('confirm')
            }}
          >
            <Icon
              className="fa-fw"
              icon={isProcessingPickup ? 'sync' : 'user'}
              spin={isProcessingPickup}
            />
            <span>
              {isProcessingPickup ? 'Processing pickup' : 'Confirm this myself'}
            </span>
          </a>
        </li>
      )}

      {canSelectCarrier && (
        <li className="standard-list__item">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              showRatesPanel()
            }}
            rel="noreferrer"
            target="_blank"
          >
            <Icon className="fa-fw" icon="truck-moving" />
            <span>Select a new carrier</span>
          </a>
        </li>
      )}

      {isBolProcessing && (
        <li className="standard-list__item">
          <a
            href=""
            onClick={e => e.preventDefault()}
            rel="noreferrer"
            target="_blank"
          >
            <Icon className="fa-fw" icon="sync" />
            <span>Processing BOL</span>
          </a>
        </li>
      )}

      {fvBolUrl && !carrierBolUrl && !hideFvBolFromSpotQuote && (
        <li className="standard-list__item">
          <FvLinkButton
            href={fvBolUrl}
            icon="file-contract"
            rel="noreferrer"
            target="_blank"
            fw
          >
            View Freightview BOL
          </FvLinkButton>
        </li>
      )}

      {carrierBolUrl && (
        <li className="standard-list__item">
          <FvLinkButton
            href={carrierBolUrl}
            icon="file-contract"
            rel="noreferrer"
            target="_blank"
            fw
          >
            View Carrier BOL
          </FvLinkButton>
        </li>
      )}

      {canUpdateTracking && (
        <li className="standard-list__item">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              setActivePanel('track')
            }}
          >
            <Icon className="fa-fw" icon="thumbtack" transform="down-1" />
            <span>Update tracking status</span>
          </a>
        </li>
      )}

      {canFinishBol && (
        <li className="standard-list__item">
          <FvLinkButton icon="file-minus" fw to={finishBolLink}>
            Finish Freightview BOL
          </FvLinkButton>
        </li>
      )}

      {canUploadBol && (
        <li className="standard-list__item">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              setShowUploadBOL(true)
            }}
          >
            <Icon icon="cloud-upload" className="fa-fw" />
            <span>Upload your own BOL</span>
          </a>

          {showUploadBOL && (
            <div className="mx-0 mb-2 mt-4">
              <UploadDocumentField
                defaultDocType="bol"
                handleClose={closeUploadField}
                loadId={loadId}
                onSuccess={closeUploadField}
              />
            </div>
          )}
        </li>
      )}

      {labels.hasLabels && (
        <li className="standard-list__item">
          <FvLinkButton
            href={
              isLegacy && workflow !== 'parcel'
                ? `${freightviewUri}/app#/shipments/${loadId}/labels`
                : labels.href
            }
            icon={labels.isLoading ? 'sync' : 'tags'}
            iconClass="fa-fw"
            onClick={() => {
              if (isLegacy || labels.href || labels.isLoading) return
              setActivePanel('label')
            }}
            rel="noreferrer"
            target="_blank"
          >
            Print labels
          </FvLinkButton>
        </li>
      )}

      <li className="standard-list__item">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            setActivePanel('message')
          }}
        >
          <Icon icon="comment-alt-lines" className="fa-fw" transform="down-1" />
          <span>Messages / Notes / Tags</span>
        </a>
      </li>

      <li className="standard-list__item">
        <FvLinkButton
          fw
          href={isLegacy ? legacyShipmentLink(loadId, workflow) : '#'}
          icon="arrow-to-right"
          to={isLegacy ? undefined : routes.details(loadId)}
        >
          More detail / options
        </FvLinkButton>
      </li>

      <li className="standard-list__item">
        <FvLinkButton fw icon="clone" onClick={repeat}>
          Repeat shipment
        </FvLinkButton>
      </li>

      {canArchive && (
        <li className="standard-list__item">
          <FvLinkButton
            fw
            icon={isArchiving ? 'spinner' : 'archive'}
            onClick={() => {
              archive({
                loadIds: [loadId],
                shouldArchive: !isArchived,
              })
            }}
          >
            {isArchived ? 'Unarchive' : 'Archive'}
          </FvLinkButton>
        </li>
      )}
    </ul>
  )
}

export default ShipmentActions
